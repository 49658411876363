<template>
  <div class="frame" @touchmove.prevent @mousewheel.prevent @click="handleClose()">
    <div style="position: relative;margin: 150px auto;width: 1025px;" @click.stop="" @touchmove.stop @mousewheel.stop>
      <img src="@/assets/pc/user/discountDetailBG.png" alt="" style="width: 100%;">

      <img class="canClick" src="@/assets/pc/user/discountClose.png" @click="handleClose()" alt=""
        style="position: absolute;right: 8px;top: 8px;width: 25px;z-index: 999;">
      <div style="position: absolute;top: 0px;left: 0;width:100%;" class="flexs_r">
        <!-- <div class="leftList" style="">
          <img :src="item.TitleUrl"  :class="selectIndex==index?'':'nolmal'" class="canClick" alt="" @click="itemClick(index)" v-for="(item,index) in list[0].data" :key="item">
          
        </div> -->
        <div style="height: 560px;white-space: nowrap;overflow-y: auto;padding: 0px 20px;margin-top:60px;width:100%;">
          <div class="content" v-if="objData.ContentPc" v-html="objData.ContentPc" style="width:100%;"></div>
          <img v-else :src="objData.DetailUrl" alt="" style="object-fit: contain;width: 100%;">

        </div>

      </div>
    </div>

  </div>

  <!-- <div class="back-drop">
  </div>

  <div class="frame" @touchmove.prevent @mousewheel.prevent @click="handleClose()">
    <div class="dialog" @click.stop="" @touchmove.stop @mousewheel.stop>
      <div class="content">
        <div class="main">
          <div class="loading" v-show="!isLoading">
            <div class="show">
              <span>Đang tải</span>
            </div>
          </div>

          <div class="wrapper" v-show="isLoading" v-html="objData.ContentPc">
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script setup>
import { bus } from '@/components/core/bus';
import { reactive, ref } from 'vue';
import { http, topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import i18n from "@/language/i18n";
import { useGetters, } from '@/store/hooks/storeState/index'
import { ElMessage } from 'element-plus';
let { isLogged } = useGetters('tcp', ["isLogged"]);
const props = defineProps(['data', 'list'])
let isLoading = ref(false);
props.data ? isLoading = true : false;
let objData = reactive(props.data)


let list = reactive(props.list)

let selectIndex = ref(0)

for (var i = 0; i < list.length; i++) {
  if (list[i].ActivityId == objData.ActivityId) {
    selectIndex.value = i
  }
}
//let selectIndex



if (isLogged) {
  // getData();
}
function getData() {
  let params = {
    ActivityId: objData.ActivityId,
    language: i18n.global.locale
  };
  mqant.request(topic.activityGetJoin, params, function (res) {
    if (res.Code == 0) {
      // that.showGet = res.Data
    } else {
      ElMessage({
        message: res.ErrMsg,
        type: 'error'
      });
    }
  });
}
function itemClick(e) {
  selectIndex.value = e


}

const handleClose = () => {
  bus.emit('closeActivityInfo');
}
// props: {
//   data: {
//     type: String,
//   }
// }
</script>

<style lang="scss" scoped>
.back-drop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #000;
  opacity: 0.5;
}

.frame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;

  .dialog {
    margin-top: 3vh;
    width: 1200px;
    padding: 20px;
    margin: 30px auto;
    background-color: #fff;
    transform: translate(0, 0);
    transition: transform 0.3s ease-out;


  }
}

.leftList {
  white-space: nowrap;
  overflow-y: auto;
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  width: 362px;
  height: 600px;

  // max-height: 53.5vw;
  img {
    width: 300px;
    height: 36px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

.nolmal {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.flexs_colum::-webkit-scrollbar {
  display: none;
}

.flexs_colum {
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}




.content {
  white-space: wrap;
  //overflow-y: auto;

  font-size: 18px;
  //white-space: break-spaces;
  word-break: keep-all;

  // white-space: normal;
  // word-break: break-all;
  // word-wrap: break-word;


  :deep(h1) {
    display: block;
    font-size: 2em !important;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  :deep(p) {
    display: block;
  //  margin-block-start: 1em;
   // margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  :deep(img) {
    width: 100%;
  }
}


</style>

<style>
table {
  border-collapse: separate; /* 将单元格之间的空白区域合并 */
}

td, th {
  border: 1px solid black !important; /* 设置边框样式为黑色实线 */
}
</style>