<template>
  <div class="home">
    <div class="container">
      <HomeHeader />
      <div class="main">
        <div class="swipeContainer">
          <van-swipe :autoplay="3000" lazy-render>
            <van-swipe-item v-for="(item, index) in bannerDate" :key="item" @click="bannerClick(item)">
              <img :src="item.PUrl" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <HorseLamp />
        <div class="tabsContainer">
          <div class="tabsComp">
            <div class="scrollContainer">
              <van-tabs v-model:active="curTabSelectIndex" :before-change="beforeChange" @click-tab="tabItemClick">
                <van-tab v-for="(item, index) in tabList">
                  <template #title>
                    <div class="iconItem" :class="curTabSelectIndex == index ? 'icon-select ' : ''">
                      <img v-if="item.hot" class="hotLabel" :src="$img('home/hot_icon.png', pc)" alt="">
                      <div class="icon">
                        <img v-if="item.name == 'Hỗ trợ'" :src="$img('common/xiaza_sj.png')" style="height:21px;" alt="">
                        <i v-else :class="'iconfont' + ' ' + item.class"></i>
                      </div>
                      <div class="name">{{ item.name }}</div>
                    </div>
                  </template>
                </van-tab>
              </van-tabs>
            </div>
          </div>
        </div>
        <div v-if="tabList[curTabSelectIndex].component" class="component">
          <component :is="tabList[curTabSelectIndex].component"></component>
        </div>
        <Float />
      </div>
    </div>
    <GiftInfo :data="showGiftInfoData" v-if="showGiftInfo" />
    <div>

    </div>
    <Qmenu />
    <!-- <img src="@/assets/mobile/common/rootActivity.gif" alt="" @click="showAllAct()" :class="showAct ? 'show' : ''"
      class="actImg">

    <div class="drawWrap " v-if="showAct" @click="closeAll()">
      <img @click.stop="showJBP()" src="@/assets/mobile/common/icon_jubaopen.png" v-if="showAll" class="acBtn acBtn1">
      <img @click.stop="showZP()" src="@/assets/mobile/common/icon_zhuanpan.png" v-if="showAll" class="acBtn acBtn2">

    </div> -->

    <JuBaoPen v-if="showjbp" @handleClose="showjbp = false"> </JuBaoPen>

  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import HomeHeader from "@/views/mobile/components/HomeHeader.vue";
import Float from "@/views/mobile/components/home/Float.vue";
import HorseLamp from "@/views/mobile/components/home/HorseLamp.vue";
import GiftInfo from "@/views/mobile/components/GiftInfo.vue";
import Recommend from "@/views/mobile/components/home/Recommend.vue";
import LiveCasino from "@/views/mobile/components/home/LiveCasino.vue";
import Sport from "@/views/mobile/components/home/Sport.vue";
import Chess from "@/views/mobile/components/home/Chess.vue";
import Fish from "@/views/mobile/components/home/Fish.vue";
import CockFight from "@/views/mobile/components/home/CockFight.vue";
import Luckywin from "@/views/mobile/components/home/Luckywin.vue";
import Slot from "@/views/mobile/components/home/Slot.vue";
import Lottery from "@/views/mobile/components/home/Lottery.vue";
import GiftView from "@/views/mobile/components/home/GiftView.vue";
import JuBaoPen from "@/views/mobile/components/home/JuBaoPen.vue";
import Qmenu from "@/views/mobile/components/Qmenu.vue";

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
let curTabSelectIndex = ref(0);
let showAct = ref(false);
let showAll = ref(false);
let showGiftInfo = ref(false);
let showGiftInfoData = ref({});
let tabList = reactive([
  {
    class: "icon-icon_nav_home",
    name: "Trang chủ",
    component: Recommend,
  },
  {
    class: "icon-icon_nav_casino",
    name: "Casino",
    component: LiveCasino,
    hot: true
  },
  {
    class: "icon-icon_nav_slots",
    name: "Nổ hũ",
    component: Slot,
    hot: true
  },
  {
    class: "icon-icon_nav_fishing",
    name: "Bắn cá",
    component: Fish,
    hot: true
  },
  {
    class: "icon-icon_nav_sports",
    name: "Thể thao",
    component: Sport,
  },
  {
    class: "icon-icon_nav_chess",
    name: "Game bài",
    component: Chess,
  },
  {
    class: 'icon-icon_nav_luckywin',
    name: 'Zwin',
    component: Luckywin,
  },
  {
    class: "icon-icon_nav_lottery",
    name: "Xổ số",
    component: Lottery,
  },
  {
    class: "icon-icon_nav_cockfighting",
    name: "Đá gà",
    component: CockFight,
  },
  {
    class: "icon-icon_nav_discounts",
    name: "Ưu đãi",
    component: GiftView,
  },
  {
    class: "icon-icon_download_btn",
    name: "Tải xuống",
    component: null,
  },
]);
let bannerSort = lobbyInfo.value.FirstPageBanner.sort((a, b) => {
  return b.Sort - a.Sort;
});
let bannerDate = reactive([]);
for (let obj of bannerSort) {
  bannerDate.push(obj);
}
bus.off(bus.event.showGiftInfo);
bus.on(bus.event.showGiftInfo, (data) => {
  if (data == false) {
    showGiftInfo.value = false;
  } else {
    showGiftInfo.value = true;
    showGiftInfoData.value = data;
  }
});

let withDrawShow = ref(false);
bus.off("fromLottery");
bus.on("fromLottery", () => {
  curTabSelectIndex.value = 1;
});
bus.off("jumpFromUserCenter");
bus.on("jumpFromUserCenter", () => {
  curTabSelectIndex.value = 9;
});
function bannerClick(val) {
  if (val.PType == "") {
    return;
  } else if (val.PType == "LinkUrl") {
    if (val.GameCode.length) {
      window.open(val.GameCode);
    }
  } else if (val.PType == "Activity") {
    setTimeout(() => {
      bus.emit("jumpFromUserCenter");
    }, 50);
    router.push({ path: "/" });

  } else {
    if (
      val.PType == "LiveCasino" ||
      val.PType == "Lottery" ||
      val.PType == "LiveSports"
    ) {
      if (val.Factory.length) {
        let name = val.Factory;
        let url = getApiLoginUrl(name, "");
        $act.openPage(url);
      } else {
      }
    } else if (
      val.PType == "Card" ||
      val.PType == "Fish" ||
      val.PType == "Slot"
    ) {
      if (val.Factory.length) {
        if (val.GameCode.length) {
          if (!isLogged.value) {
            router.push({ name: "LoginRegist" });
            return;
          }
          let url = getApiLoginUrl(val.Factory, val.GameCode);
          $act.openPage(url);
        } else {
          var name;
          if (val.PType == "Fish") {
            name = "fish";
          } else if (val.PType == "Slot") {
            name = "slot";
          } else {
            name = "chess";
          }
          router.push({
            name: name,
            params: {
              FactoryName: val.Factory,
            },
          });
        }
      }
    }
  }
}

let showjbp = ref(false)
let showTurn = ref(false)
bus.on('showJBP', (val) => {
  showjbp.value = true
})
// function showJBP() {
//   if (!isLogged.value) {
//     router.push({ name: "LoginRegist" });
//     return;
//   }
//   showjbp.value = true
//   closeAll()
// }

// function showZP() {
//   if (!isLogged.value) {
//     router.push({ name: "LoginRegist" });
//     return;
//   }
//   router.push({ name: "turntable" });
//   return;
// }
// function closeAll() {
//   showAct.value = false

//   showAll.value = false

// }
// function showAllAct() {

//   showAct.value = !showAct.value
//   setTimeout(() => {
//     showAll.value = showAct.value
//   }, 100);


// }
function beforeChange(index) {
  // 返回 false 表示阻止此次切换,下载界面
  if (index === tabList.length - 1) {
    return false;
  } else {
    return true;
  }
}
function tabItemClick(tab) {
  if (tab.name == tabList.length - 1) {

    if (tools.platform() == 'h5_android') {
      $act.openPage(lobbyInfo.value.VersionGet.android.UrlPath)
    } else {
      //下载界面需要跳转
      $act.openPage("https://i5bet.com/static/download/index.html");
      return;
    }

  }
}
</script>

<style lang="scss" scoped>
.actImg {
  transition: all .1s;
  position: fixed;
  width: 50px;
  right: 20px;
  bottom: 160px;
  z-index: 1001;

}

.show {
  right: calc(50vw - 25px);
  bottom: calc(50vh - 25px);
}

.acBtn {
  position: fixed;
  width: 70px;
  transition: all .3s ease-in-out;
  z-index: 1005;
  bottom: calc(50vh - 40px);

}

.acBtn1 {
  animation: acBtn1 0.3s;
  right: calc(50vw + 35px);

}

.acBtn2 {
  animation: acBtn2 0.3s;
  right: calc(50vw - 100px);

}

@keyframes acBtn1 {
  from {
    opacity: 0;
    right: calc(50vw - 35px);
  }

  to {
    opacity: 1;
    right: calc(50vw + 35px);
  }
}

@keyframes acBtn2 {
  from {
    opacity: 0;
    right: calc(50vw - 35px);
  }

  to {
    opacity: 1;
    right: calc(50vw - 100px);
  }
}




.drawWrap {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.home {
  // position: absolute;
  width: 100%;
  // top: 0;
  // bottom: 50px;
  position: relative;
  top: 0px;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    position: relative;
    overflow: hidden;

    .main::-webkit-scrollbar {
      display: none;
    }

    .main {
      /* 隐藏滚动条 */
      scrollbar-width: none;
      /* firefox */
      -ms-overflow-style: none;
      /* IE 10+ */
    }

    .main {
      overflow-y: auto;
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;

      .swipeContainer {
        width: 100%;
        height: 110px;
        position: relative;

        .van-swipe-item {
          width: 100%;
          color: #fff;
          font-size: 5.333333vw;
          line-height: 29.333333vw;
          height: 29.333333vw;
          text-align: center;

          img {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 29.333333vw;
            pointer-events: none;
          }
        }
      }

      .noticeContainer {
        width: 100%;
        height: 24px;
        color: #e9cfa4;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;

        .noticeBar {
          overflow: hidden;
          display: flex;
          align-items: center;
          width: 100%;

          .icon {
            display: flex;
            align-items: center;
            padding: 0 0.533333vw 0 1.6vw;
            font-size: 3.733333vw;
            color: #e9cfa4;

            img {
              width: 22px;
            }
          }
        }
      }

      .tabsContainer {
        .tabsComp {
          position: relative;
          width: 100%;
          height: 17.6vw;
          background: #1b233d;
          display: flex;
          align-items: center;
          padding-top: 0.533333vw;

          .scrollContainer {
            height: 16vw;
            width: 100%;

            :deep(.van-tabs) {
              height: 16vw;

              .van-tabs__wrap {
                display: block;
                height: 16vw;

                .van-tabs__line {
                  padding: 0;
                  background: 0 0;
                }

                .van-tabs__nav--line {
                  padding: 0;
                  background: 0 0;
                  overflow-x: auto;
                  overflow-y: hidden;
                  box-sizing: content-box;
                  height: 100%;
                  position: relative;
                  display: flex;
                  user-select: none;

                  .van-tab {
                    padding: 0 1px;
                    flex: 1 0 auto;
                    display: flex;
                    position: relative;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    font-size: 3.733333vw;
                    line-height: 5.333333vw;
                    cursor: pointer;

                    .iconItem {
                      width: 15.933333vw;
                      height: 15.933333vw;
                      display: block;
                      overflow: hidden;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;

                      .hotLabel {
                        position: absolute;
                        z-index: 2;
                        transform: translateZ(0);
                        height: 6.933333vw;
                        top: -1.066667vw;
                        right: -1.6vw;
                        transform-origin: 36% 20%;
                        animation: rotate .8s ease-in-out;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;
                      }

                      @keyframes rotate {
                        0% {
                          transform: rotate(0deg);
                        }

                        100% {
                          transform: rotate(30deg);
                        }
                      }

                      .icon {
                        display: flex;
                        align-items: center;

                        i {
                          color: #b3b9da;
                          font-size: 22px;
                        }
                      }

                      .name {
                        white-space: nowrap;
                        word-break: break-word;
                        text-align: left;
                        overflow: hidden;
                        position: relative;
                        color: #b3b9da;
                        margin-top: 5px;
                        font-size: 13px;
                      }
                    }

                    .icon-select {
                      .icon {
                        i {
                          color: #f7e00c;
                        }
                      }

                      .name {
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
