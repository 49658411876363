<template>
    <div class="event-qmenu" :class="!isShowVip ? 'menu-close' : ''" @click="isShowVip = !isShowVip">
        <div class="handle">
            <img src="@/assets/pc/home/jiantou_bg.png" alt="">
            <img class="dir" :class="isShowVip ? 'dir-x' : ''" src="@/assets/pc/home/jiantou_d.png">
        </div>
        <div class="cont">
            <div class="event-item">
                <img class="bg" src="@/assets/pc/home/icon_zhuanpan.png" @click="toturnTable()">
                <!-- <img class="bg" src="@/assets/pc/home/icon_jubaopen.png" @click="toJBP"> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);

const isShowVip = ref(true);

const toJBP = () => {
    if (!isLogged.value) {
        bus.emit(bus.event.loginState, true);
        return;
    }
    bus.emit('showJBP');
};

const toturnTable = () => {
    if (!isLogged.value) {
        bus.emit(bus.event.loginState, true);
        return;
    }
    router.push({ name: "turntable" });
};
</script>

<style lang="scss" scoped>
.event-qmenu {
    animation: aqm 1.8s ease infinite;
    bottom: 15%;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    left: 0;
    min-height: auto !important;
    position: fixed;
    transition: .3s ease;
    width: 110px;
    z-index: 21;

    .handle {
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        position: absolute;
        right: 0px;
        width: 30px;

        .dir {
            left: 40%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            position: absolute;
            width: 10px;
        }

        .dir-x {
            left: 30%;
            transform: translate(-50%, -50%) scaleX(-1);
        }
    }

    .cont {
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
        border: 3px solid #52b9dd;
        border-bottom-right-radius: 15px;
        border-left: 0;
        border-top-right-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        min-height: 103px;
        padding: 10px;
        width: 80px;

        .event-item {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 5px 0;
            position: relative;
            transition: .3s ease;
            width: 60px;

            img {
                display: block;
                width: 150%;
            }
        }
    }
}

.menu-close {
    transform: translateX(-83px);
}

@keyframes aqm {
    0% {
        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    }

    55% {
        filter: drop-shadow(0 2px 6px #52b9dd);
    }

    70% {
        filter: drop-shadow(0 2px 6px #68c2e1);
    }

    100% {
        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    }
}
</style>