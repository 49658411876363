<template>
        <div>
                <h1 style="text-align: center;"><strong>VỀ CHÚNG TÔI</strong></h1>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com là công ty cá cược uy tín
                                thuộc tập đoàn M.A.N Entertainment, có trụ sở chính tại Philippines, và được cấp phép hợp pháp
                                thông qua Khu kinh tế Isle of Man &amp; Cagayan Economic Zone and Free Port. Hiện nay, Nhà cái
                                cá cược đang phủ sóng trên khắp thị trường cá cược trực tuyến không thể nào không kể đến cái tên
                                i5bet.com. Việc mang đến cho người chơi những phút giây thư giãn và vui vẻ chính là điều mà
                                i5bet.com luôn hướng đến, phát triển với chữ “Tín” đi đầu, chắc chắn sẽ mang đến cho các game
                                thủ những trải nghiệm không thể nào quên.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Giới thiệu i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com là 1 thương hiệu cá cược uy
                                tín hoạt động gần 20 năm trên thị trường cá cược cho đến thời điểm hiện tại. i5bet.com đã sở
                                hữu số lượng thành viên đông đảo, đáng chú ý đó là ngay từ những ngày đầu ra mắt. i5bet.com đã
                                nhanh chóng sở hữu giấy phép hoạt động hợp pháp từ cơ quan cá cược cấp cao.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Chính vì vậy, Nhà cái i5bet.com đã và
                                đang đủ điều kiện để trở thành hợp pháp trên thị trường Châu Á – Châu Âu. Hiện nay, i5bet.com
                                đã và đang không ngừng áp dụng những công nghệ tiên tiến và hiện đại nhất. Bộ phận chuyên viên
                                làm việc, nỗ lực không ngừng nghỉ để đưa ra những ý tưởng để nâng cao chất lượng dịch vụ tại
                                .</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Do đó, i5bet.com casino đã được đông
                                đảo game thủ đánh giá cao và sân chơi chất lượng trong thời điểm hiện tại. Với số lượng thành
                                viên đang trải nghiệm tại cùng với lượt đăng ký gia tăng không ngừng. Chắc chắn đây sẽ là sân
                                chơi uy tín và an toàn mà bạn có thể chọn mặt gửi vàng</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Những tính năng vượt trội của i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com là Nhà cái được biết đến sở
                                hữu nhiều ưu điểm vượt trội so với những sân chơi khác trên thị trường online. Cũng chính vì
                                vậy, đã chiếm lĩnh sự tin tưởng của người chơi. Vậy tại sân chơi này có những ưu điểm vượt trội
                                gì?</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Giao diện thiết kế đẹp mắt</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Sau khi truy cập vào hệ thống
                                i5bet.com , người chơi có thể thấy được giao diện được thiết kế với một màu xanh làm chủ đạo.
                                Hình ảnh và chất lượng âm thanh sống động tại đây phải nói đỉnh cao, luôn cố gắng để có thể mang
                                đến cho thành viên những điều kiện tuyệt vời nhất. Bên cạnh đó, những danh mục trò chơi và dịch
                                vụ trên hệ thống cũng đã được sắp xếp một cách rõ ràng.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nếu bạn là những thành viên mới tham
                                gia tại đây, khi muốn tham gia vào danh mục cá cược nào cũng có thể dễ dàng tìm thấy và sử dụng
                                bởi vì i5bet.com luôn muốn người chơi của mình có được những trải nghiệm tuyệt vời nhất. Có thể
                                nói, giao diện tại i5bet.com chính là yếu tố để người chơi có thể đánh giá về chất lượng sản
                                phẩm cũng như sự đầu tư mà hướng đến.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Thương hiệu uy tín – an toàn – chất lượng</strong></span>
                </p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Đây là yếu tố đầu tiên mà người chơi
                                quan tâm khi lựa chọn cá cược trực tuyến. Nhà cái i5bet.com là thương hiệu uy tín hàng đầu trên
                                thị trường được thể hiện thông qua những yếu tố đã được nhiều chuyên gia công nhận. Đầu tiên,
                                phải kể đến đó là thuộc tập đoàn Wilshire Worldwide và trở thành Nhà cái đầu tiên được cung cấp
                                giấy phép từ PAGCOR.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Cùng với đó, sân chơi này cũng đã chính
                                thức hoạt động công khai và minh bạch 100% dưới sự giám sát của chính phủ Philippines. Chính vì
                                vậy mà cơ chế hoạt động cũng sẽ tuân thủ theo quy định nghiêm ngặt nhất. Để giúp người chơi có
                                nhiều sự lựa chọn khi tham gia cá cược, i5bet.com đã hợp tác cùng nhiều đơn vị cung cấp dịch vụ
                                cá cược nổi tiếng. Điển hình đó là SEXY BACCARAT, AG, DG, WM, SABA, CMD, JILI, CQ9,…</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Giao dịch nhanh chóng</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Tại i5bet.com , người chơi có thể lựa
                                chọn đa dạng hình thức nạp rút tiền như tài khoản ngân hàng Sacombank, Vietcombank, BIDV,
                                Vietinbank … thẻ cào điện thoại, ví điện tử Momo, Zalopay, Viettelpay, cổng thanh toán điện tử.
                                Giao dich gửi tiền được xử lý ngay lập tức kể từ khi nhận được khoản chuyển. Thời gian rút tiền
                                trong vòng 2 phút, tối đa 10 phút. Mọi thắc mắc xảy ra trong quá trình giao dịch thì bạn có thể
                                liên hệ CSKH để được hỗ trợ xử lý nhanh chóng.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Dịch vụ chăm sóc khách hàng i5bet.com tận
                                        tâm</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Thành viên có thể yên tâm khi tham gia
                                sử dụng những sản phẩm trên hệ thống . Với sự hỗ trợ tận tâm của hệ thống chăm sóc khách hàng
                                tại trang web. Chắc chắn toàn bộ thông tin cũng như vấn đề sẽ được giải quyết một cách nhanh
                                chóng nhất. Tư vấn viên của i5bet.com sẽ túc trực 24/7 và người chơi cũng sẽ có nhiều cách thức
                                liên hệ.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Để nhận được sự giải đáp như là: qua số
                                hotline, telegram, zalo, … Ngoài ra với sự ưu tiên tuyệt đối dành cho thành viên của hệ thống
                                i5bet.com sẽ hỗ trợ những yêu cầu cũng như giải quyết sự cố của bạn đang gặp phải.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>An ninh bảo mật tuyệt đối</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Chắc chắn điều khiến nhiều thành viên
                                lo lắng và quan tâm nhất khi tham gia cá cược trực tuyến chính là bảo mật.. Tuy nhiên, khi bạn
                                tham gia vào hệ thống cá cược của i5bet.com , thành viên có thể hoàn toàn yên tâm về vấn đề bảo
                                mật thông tin.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com đã cam kết sẽ không bao giờ
                                để lộ thông tin cá nhân của thành viên. Phương thức thanh toán đa dạng, đảm bảo an ninh và bảo
                                mật thông tin. Với việc sở hữu hệ thống bảo mật tiên tiến và hiện đại nhất hiện nay. Sẽ giúp cho
                                việc bảo mật cực kỳ hiệu quả, với hệ thống tối tân như vậy việc người chơi bị xâm phạm thông tin
                                sẽ được ngăn chặn một cách nhanh chóng và kịp thời</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Sự đa dạng sản phẩm cá cược chỉ có tại
                                        i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Đến với kho trò chơi của i5bet.com ,
                                người chơi sẽ thấy được một kho tàng đồ sộ với những sảnh cược vô cùng đa dạng bao gồm các sản
                                phẩm như: Trò Chơi, Sòng Bài, Thể thao, Bắn cá, E-Sports, Xổ Số. Nhiều sản phẩm đa dạng bạn có
                                thể lựa chọn.. Người chơi sẽ được trải nghiệm cảm giác như đang trong sòng bài quốc tế. Dưới đây
                                là những sảnh game nổi bật tại i5bet.com .</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Hệ thống CASINO trực tuyến</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com casino là một trong số ít
                                những công ty giải trí hợp tác chính thức với hầu hết nhà cung cấp giải trí sản phẩm Casino, nổi
                                bật nhất chiếm trọn số lượng người chơi tham gia. Người chơi sẽ được trải nghiệm với không gian
                                vô cùng chân thực tại các sảnh game như AE Sexy, WM, AG Gaming, SA Gaming, WM Gaming, EVO
                                Gaming, SA, Play Tech,.. Hàng loạt hình thức đổi thưởng đều xuất hiện tại đây; cùng với đó thành
                                viên cũng sẽ được nhìn ngắm những dealer vô cùng xinh đẹp.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Không chỉ tham gia giải tỏa những giây
                                phút căng thẳng mà bạn cũng có thể kiếm được số tiền cực khủng tại đây. Có thể nói đến với
                                i5bet.com chính là là đến với thiên đường của Casino giải trí. Khi hệ thống sở hữu đầy đủ những
                                trò chơi truyền thống cho đến hiện đại như baccarat, poker, roulette, sicbo, dragon tiger,… Và
                                chắc chắn nếu là thành viên khó tính nhất cũng sẽ bị chinh phục ngay từ lần đầu tiên.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Cá cược thể thao</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Tại i5bet.com có đa dạng kèo đặt cược
                                cùng các trò chơi cá cược thể thao hot nhất. Nhà cái không chỉ cung cấp cá cược bóng đá mà còn
                                những sản phẩm như là bóng rổ, đua xe, tennis, đua ngựa, quyền anh, bơi lội,… và những bộ môn
                                thể thao khác như thể thao ảo, e-sport.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Khi nhắc đến i5bet.com người chơi sẽ
                                không thể nào bỏ qua sảnh cược bóng đá. Trò chơi này đang chiếm số lượng lớn thành viên tham gia
                                đặt cược. Điều tạo nên sự hấp dẫn của những trò chơi bóng đá đó chính là sự đa dạng của kèo đặt
                                cược. Từ những kèo truyền thống như là trên dưới, tài xỉu, đội ngũ bình luận viên và chuyên gia
                                soi kèo tại còn lên kèo phạt góc, kèo thẻ vàng, kèo penalty,… Cũng chính vì như vậy đã giúp
                                người chơi có nhiều sự lựa chọn hơn.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Đá gà i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Đá gà là trò chơi cá cược truyền thống
                                dân gian lâu đời của Việt Nam. Tuy nhiên hiện nay các bạn đã có thể chơi cá cược đá gà trực tiếp
                                trên mạng mà không cần phải ra sân đá như trước nữa. Tại i5bet.com , các bạn sẽ được tận hưởng
                                các trận đá gà ở các đấu trường lớn nhất trên thế giới. Hệ thống âm thanh sôi động và hình ảnh
                                chân thực không làm mất đi tính chân thực của nó. Tại i5bet.com , có các giải đấu gà quốc tế
                                lớn bao gồm: Đá gà cựa sắt, cựa dao, đá gà campuchia, philipines,..</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Bắn cá i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Bắn cá là trò chơi bắt nguồn từ các
                                siêu thị, trung tâm thương mại truyền thống. Thế nhưng, i5bet.com đã tận dụng và phát triển
                                game bắn cá lên tầm cao mới. Tại đây, các bạn sẽ được tận hưởng cảm giác bắn cá online mọi lúc
                                mọi nơi thông qua chiếc điện thoại di động hoặc máy tính. Có các trò chơi bắn cá hấp dẫn như:
                                bắn cá đổi thưởng, bắn cá đổi tiền mặt, bắn cá ăn xu, bắn cá rồng, bắn cá mập, câu rùa, nàng
                                tiên cá,… cho các bạn tha hồ trải nghiệm.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Xổ số – lô đề i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Hệ thống xổ số được ra đời dựa vào thực
                                tế khi người chơi đánh xổ số, lô đề có tỷ lệ ăn tiền thấp tại các đại lý của Việt Nam. Chính vì
                                vậy đã cho ra mắt hệ thống đánh lô đề trực tuyến. Thành viên không cần di chuyển trực tiếp đến
                                các nhà đại lý. Mà bạn vẫn có thể tham gia trực tiếp trên Nhà cái của i5bet.com ; có thể nói
                                tại đây người chơi sẽ được chuyển tiền thắng cược về tài khoản của bạn.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Bên cạnh đó, sở hữu tỷ lệ 1:99.5 đây là
                                con số cao top đầu trên thị trường cá cược hiện nay. Cũng chính vì vậy, hiện nay có rất nhiều lô
                                thủ tham gia trải nghiệm tại &nbsp;sảnh xổ số của i5bet... Với giao diện dễ nhìn tỷ lệ cược hấp
                                dẫn cùng tỷ lệ thắng cược cao cũng như có hệ thống soi cầu miễn phí. Chắc chắn i5bet.com là địa
                                chỉ hấp dẫn mà người chơi không nên bỏ qua.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Slot game i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Sảnh slot game đã được i5bet.com
                                &nbsp; nâng cấp đến mức gần như hoàn hảo, chắc chắn khó có nhà cái nào có thể sánh bằng tại sảnh
                                cược này. Có thể nói hệ thống slot chính là một trong những sản phẩm thu hút đông đảo người chơi
                                nhiều nhất của i5bet.com . Đó là nhờ sự đầu tư cực kỳ mạnh mẽ về đồ họa và tốc độ truy
                                cập.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Chính vì nắm bắt được xu hướng của game
                                slot trong thị trường hiện nay. i5bet.com cũng đã mở rộng đầu tư vào những trò chơi 3D này.
                                Những trò chơi mà bạn sẽ được trải nghiệm sẽ là nàng tiên cá, đào vàng, xếp kẹo,.. Và i5bet.com
                                cũng đã phát triển những trò chơi ăn tiền thật vô cùng thú vị và đa dạng.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Các trò chơi khác tại i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Ngoài các trò chơi trên thì i5bet.com
                                còn có các game mới lạ cho người chơi trải nghiệm như: đánh bài 3D, fan tan, đảo giấu vàng, kim
                                ngưu may mắn, giả kim thuật, con vật may mắn, hành tinh kẹo,…</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Link vào i5bet.com chính thức</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Là một Nhà cái nổi tiếng nhất nhì trong
                                thị trường cá cược trực tuyến hiện nay. Việc có nhiều trang web giả mạo là điều không thể tránh
                                khỏi. Chính vì vậy i5bet.com liên tục cho ra mắt những đường link đăng ký đăng nhập i5bet.com
                                &nbsp;chính thức để có thể tránh khỏi việc truy cập vào những giả danh.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Người chơi cần phải kiểm tra chi tiết
                                trước khi thực hiện đăng ký. Sẽ an toàn hơn nếu như bạn thực hiện tải app và đăng ký hoặc đăng
                                nhập trên ứng dụng. Ngoài ra, thành viên cũng có thể tham gia vào những đường link mà đại lý
                                cung cấp. Để có thể tránh được những sự cố không mong muốn xảy ra. Hãy truy cập vào trang chủ
                                i5bet.com casino qua đường link sau: i5bet.com .</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Chương trình khuyến mãi i5bet.com siêu hấp
                                        dẫn</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Ưu đãi khuyến mãi chắc chắn không thể
                                nào thiếu tại i5bet.com , đây được xem là động lực rất lớn dành cho người chơi. Nhà cái có tiềm
                                lực tài chính vô cùng dồi dào, cho nên những sự kiện khuyến mãi luôn có giá trị cực lớn. Hiện
                                nay, i5bet.com đang sở hữu những ưu đãi như sau:</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Khuyến mãi thành viên mới</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nếu như bạn là những thành viên mới
                                tham gia tại i5bet.com thì người chơi có thể hoàn toàn yên tâm. Tại i5bet.com luôn mang đến
                                cho người chơi những ưu đãi hấp dẫn. Bên cạnh đó, nếu là lần đầu tiên tham gia nạp tiền tại các
                                sảnh game; thì ngoài việc nhận ưu đãi từ chúng tôi bạn cũng sẽ được nhận các phần thưởng có giá
                                trị không hề nhỏ. Điển hình đó là người chơi sẽ được nhận 100% tổng giá trị tiền nạp trong lần
                                đầu tiên. Vậy nên, đã có rất nhiều người chơi tham gia để nhận thưởng.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Hoàn trả cực khủng lên đến 1.8%</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Có thể nói, khuyến mãi hoàn trả chính
                                là ưu đãi mà người chơi mong chờ nhất khi tham gia đặt cược. Tại i5bet.com , cho dù người chơi
                                đặt cược vào bất kỳ sảnh game nào. Từ cá cược casino, thể thao, nổ hũ, game bài đổi thưởng,… bạn
                                sẽ nhận được hoàn trả lên tới tỷ lệ 1.8% từ số tiền đặt cược. Có nghĩa là cho dù thắng hay thua
                                cược, người chơi cũng sẽ nhận được tiền hoàn trả từ chúng tôi.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Khuyến mãi thành viên VIP</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nếu như bạn là thành viên VIP tại hệ
                                thống i5bet.com chắc chắn sẽ nhận được nhiều phần quà hấp dẫn với giá trị cực khủng. Thành viên
                                VIP là vị trí mà rất nhiều game thủ ao ước. Nếu bạn đã may mắn đạt được cấp bậc cao nhất này thì
                                sẽ nhận được ưu đãi cực lớn. Thông thường, khi i5bet.com có bất kỳ chương trình khuyến mãi mới,
                                thành viên vip sẽ được nhận thông báo sớm nhất. Cũng như tham gia nhận thưởng đầu tiên, nếu như
                                ưu đãi đó có số lượng thì sẽ rất có lợi cho bạn.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Ưu đãi khác tại i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nhà cái i5bet.com luôn biết chiều lòng
                                người chơi vào những dịp lễ, sinh nhật. Hệ thống sẽ tổ chức những chương trình ưu đãi; nhằm mục
                                đích tạo điều kiện cho người chơi có thể rinh quà liền tay. Và đặc biệt hơn những dịp lễ hội
                                cũng là lúc ra mắt nhiều ưu đãi nhất. Điển hình như là: cơn mưa lì xì, vòng quay may mắn, khuyến
                                mãi nghìn tỷ ngày 08 hàng tháng, Siêu lì xì bí mật ngày 22 hàng tháng… i5bet.com luôn nâng cấp
                                hệ thống của mình để tạo ra sự mới mẻ để có thể tạo sự hấp dẫn đến người chơi. Giúp thành viên
                                không bị nhàm chán khi trải nghiệm sân chơi cá cược của chúng tôi.</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Tải app i5bet.com – cá cược mọi lúc mọi nơi</strong></span>
                </p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Ứng dụng được phát triển và cho ra mắt
                                nhằm mục đích phục vụ người chơi tại i5bet.com . Ứng dụng này đã đảm bảo về tính an toàn và
                                chuyên nghiệp. Ngoài ra, nhà phân phối hệ thống game cá cược chất lượng cho nên những giao dịch
                                của bạn trên ứng dụng. Cũng sẽ được bảo đảm về tính trung thực và bảo mật tuyệt đối.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Giao diện của ứng dụng được thiết kế
                                một cách đẹp mắt và tươi sáng. Được xây dựng bởi đội ngũ giàu kinh nghiệm sẽ giúp bạn có được
                                những trải nghiệm tuyệt vời nhất. Bên cạnh đó, khi tải i5bet.com phiên bản điện thoại về máy,
                                người chơi cũng có thể tham gia đặt cược mọi lúc mọi nơi. Chỉ cần đăng nhập và nạp tiền thành
                                công bạn có thể giải trí thỏa thích trên ứng dụng này</span></p>
                <p><span style="color: rgb(255, 255, 255);"><strong>Lời kết</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Có thể nói i5bet.com là Nhà cái tiên
                                phong cho lĩnh vực giải trí trực tuyến. Cùng với đó, sân chơi của i5bet.com cũng đã được đánh
                                giá chất lượng hàng đầu khu vực Châu Á. Nếu như bạn đang tìm kiếm cho mình một địa chỉ giải trí
                                uy tín và an toán. Thành viên có thể tham gia sân chơi của i5bet.com , đảm bảo sẽ không làm bạn
                                thất vọng. Vậy nên hãy nhanh tay đăng ký tham gia trở thành thành viên tại đây nhé !</span></p>
                <p><span style="color: rgb(255, 255, 255);"> </span></p>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>