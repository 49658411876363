<template>
  <div v-if="loadSucess" class="recharge">
    <div class="tab">
      <ul>
        <li v-for="(item, index) in 1" :style="index > 3 ? 'margin-top:20px;' : ''" :key="rechargeList[0]" :class="selectIndex == index ? 'selected' : 'nolmal'" @click="itemClick(index)">
          <div :class="rechargeList[0].class"></div>
          <span>{{ rechargeList[0].name }}</span>
          <img v-if="selectIndex == index" class="flag" :src="$img('user/goux.png')" alt="" />
        </li>
      </ul>
    </div>
    <div v-if="((selectIndex == 1 || selectIndex == 2 || selectIndex == 3) && rechargeList[selectIndex].payTypeList.length > 1)" class="choose-pay">
      <div class="tagsDiv">
        <span v-for="(item, index) in rechargeList[selectIndex].payTypeList " :class="index == rechargeList[selectIndex].payTypeIndex ? 'active' : ''" @click="rechargeList[selectIndex].payTypeIndex = index">
          {{ item.payType }}
        </span>
      </div>
    </div>

    <div class="not-lock">
      <div class="body">
        <div class="show-info" v-if="selectIndex < 4">
          <h3>
            <span>Chuyển khoản ngân hàng</span>
          </h3>
          <p>
            <span>Giới hạn nạp tối thiểu</span>
            <span>
              <b>{{
                $tools.formatNum2k(rechargeList[selectIndex].payData.Mini)
              }}~</b>
              <b>{{
                $tools.formatNum2k(rechargeList[selectIndex].payData.Max)
              }} VND(K)</b>
            </span>
            <span></span>
          </p>
        </div>
        <div class="frame-blank" v-if="selectIndex == 0">
          <form class="from-blank">
            <div class="choose-blank">
              <p>
                <span>Vui lòng chọn ngân hàng</span>
              </p>

              <div class="form-control canClick" style="color: white; margin-top: 0" @click="showPicker0 = !showPicker0">
                {{ rechargeList[selectIndex].curBankItem.BankName }}

                <img src="@/assets/pc/user/xiala_img.png" style="
                  width: 13px;
                  position: absolute;
                  right: 16px;
                  top: 13px;
                " />

                <div v-if="showPicker0" class="selectArea" style="width: 100%; top: 34px; left: 0">
                  <div @click.stop="firstClick(item)" :style="rechargeList[selectIndex].curBankItem == item ? 'background-color: #4a69ff' : ''
                    " v-for="(item, index) in rechargeList[selectIndex].bankList" class="selectItem middleSize boldSize" :key="item">
                    {{ item.BankName }}
                  </div>
                </div>
              </div>

            </div>
            <div class="input-money">
              <div class="left-frame">
                <ul>
                  <li>
                    <span>Tên tài khoản</span>
                    <span>{{
                      rechargeList[selectIndex].curBankItem.AccountName
                    }}</span>
                    <img style="width: 19px; height: 19px" @click="
                    copyUrl(
                      rechargeList[selectIndex].curBankItem.AccountName
                    )
                      " src="@/assets/pc/user/copy.png" alt="" />
                  </li>
                  <li>
                    <span>Số tài khoản</span>
                    <span>{{
                      rechargeList[selectIndex].curBankItem.CardNumber
                    }}</span>
                    <img style="width: 19px; height: 19px" @click="
                    copyUrl(
                      rechargeList[selectIndex].curBankItem.CardNumber
                    )
                      " src="@/assets/pc/user/copy.png" alt="" />
                  </li>
                  <li>
                    <span>Nội dung chuyển tiền</span>
                    <span>{{ rechargeList[selectIndex].remark }}</span>
                    <img style="width: 19px; height: 19px" @click="copyUrl(rechargeList[selectIndex].remark)" src="@/assets/pc/user/copy.png" alt="" />
                  </li>
                </ul>
              </div>
              <div class="right-frame">
                <from class="frame-from">
                  <div class="money">
                    <p>Nạp tiền:</p>

                    <input v-if="chargeNum" class="input-control" disabled v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                    <input v-else class="input-control" v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                    <div class="exchange">
                      =
                      {{
                        $tools.formatNum(rechargeList[selectIndex].money * 1000)
                      }}
                      VND
                    </div>
                    <div style="color:red;">vui lòng chuyển khoản trước khi làm lệnh nạp</div>
                  </div>
                  <!-- <div class="blank">
                    <p>Số tài khoản ngân hàng</p>
                    <input
                      class="input-control"
                      v-model="rechargeList[selectIndex].accountName"
                      type="text"
                      placeholder="Vui lòng nhập số tài khoản ngân hàng"
                    />
                  </div> -->
                  <div class="submit" @click="reCharge()">
                    <span>Thanh toán ngay bây giờ</span>
                  </div>
                </from>
              </div>
            </div>
          </form>
        </div>
        <div class="frame-common" v-if="selectIndex == 1">
          <from class="from-comon">
            <div class="get-money">
              <p>Nạp tiền:</p>
              <div class="input-group">
                <input v-if="chargeNum" class="input-control" disabled v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <input v-else class="input-control" v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <div class="text-danger">
                  <span>={{
                    $tools.formatNum(rechargeList[selectIndex].money * 1000)
                  }}
                    VND</span>
                </div>
              </div>
              <div class="text-dec">
                <!-- <p>
                  <span>phí thủ tục:</span>
                  <span>0</span>
                </p>
                <p>
                  <span>Số tiền gửi thực tế:</span>
                  <span>0</span>
                </p> -->
                <!-- <div class="text-danger">
                  <span
                    >={{
                      $tools.formatNum(rechargeList[selectIndex].money)
                    }}</span
                  >
                </div> -->
              </div>
            </div>

            <br />
            <div>
              <button class="btn submit-btn" @click="reCharge()">
                Thanh toán ngay bây giờ
              </button>
            </div>
          </from>
        </div>
        <div class="frame-common" v-if="selectIndex == 2">
          <from class="from-comon">
            <!-- <div class="choose-blank"> -->
            <!-- <p>
              <span>Vui lòng chọn ngân hàng</span>
            </p>
            <div class="form-control canClick" style="color: white; margin-top: 0" @click="showPicker1 = !showPicker1">
              {{ rechargeList[selectIndex].curBankItem.Name }}

              <img src="@/assets/pc/user/xiala_img.png" style="
                  width: 13px;
                  position: absolute;
                  right: 16px;
                  top: 13px;
                " />

              <div v-if="showPicker1" class="selectArea" style="width: 100%; top: 34px; left: 0">
                <div @click.stop="firstClick(item)" :style="rechargeList[selectIndex].curBankItem == item ? 'background-color: #4a69ff' : ''
                  " v-for="(item, index) in rechargeList[selectIndex].bankList" class="selectItem middleSize boldSize"
                  :key="item">
                  {{ item.Name }}
                </div>
              </div>
            </div> -->

            <!-- <select
                class="form-control"
                v-model="rechargeList[selectIndex].curBankItem"
              >
                <option
                  v-for="item in rechargeList[selectIndex].bankList"
                  :key="item"
                  :value="item.Name"
                >
                  {{ item.Name }}
                </option>
              </select> -->
            <!-- </div> -->
            <div class="get-money">
              <p>Nạp tiền:</p>
              <div class="input-group">
                <input v-if="chargeNum" class="input-control" disabled v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <input v-else class="input-control" v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <div class="exchange">
                  =
                  {{
                    $tools.formatNum(rechargeList[selectIndex].money * 1000)
                  }}
                  VND
                </div>
              </div>
              <!-- <div class="text-dec">
                <p>
                  <span>phí thủ tục:</span>
                  <span>0</span>
                </p>
                <p>
                  <span>Số tiền gửi thực tế:</span>
                  <span>0</span>
                </p>
                <div class="text-danger">
                  <span
                    >={{
                      $tools.formatNum(rechargeList[selectIndex].money)
                    }}</span
                  >
                </div>
              </div> -->
            </div>

            <br />
            <div>
              <button class="btn submit-btn" @click="reCharge()">
                Thanh toán ngay bây giờ
              </button>
            </div>
          </from>
        </div>
        <div class="frame-common" v-if="selectIndex == 3">
          <from class="from-comon">
            <!-- <div class="choose-blank"> -->
            <!-- <p>
              <span>Vui lòng chọn ngân hàng</span>
            </p>
            <div class="form-control canClick" style="color: white; margin-top: 0" @click="showPicker2 = !showPicker2">
              {{ rechargeList[selectIndex].curBankItem.Name }}

              <img src="@/assets/pc/user/xiala_img.png" style="
                  width: 13px;
                  position: absolute;
                  right: 16px;
                  top: 13px;
                " />

              <div v-if="showPicker2" class="selectArea" style="width: 100%; top: 34px; left: 0">
                <div @click.stop="firstClick(item)" :style="rechargeList[selectIndex].curBankItem == item ? 'background-color: #4a69ff' : ''
                  " v-for="(item, index) in rechargeList[selectIndex].bankList" class="selectItem middleSize boldSize"
                  :key="item">
                  {{ item.Name }}
                </div>
              </div>
            </div> -->

            <!-- <select
                class="form-control"
                v-model="rechargeList[selectIndex].curBankItem"
              >
                <option
                  v-for="item in rechargeList[selectIndex].bankList"
                  :key="item"
                  :value="item.Name"
                >
                  {{ item.Name }}
                </option>
              </select> -->
            <!-- </div> -->
            <div class="get-money">
              <p>Nạp tiền:</p>
              <div class="input-group">

                <input v-if="chargeNum" class="input-control" disabled v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <input v-else class="input-control" v-model="rechargeList[selectIndex].money" type="number" placeholder="Vui lòng nhập số tiền" />
                <div class="exchange">
                  =
                  {{
                    $tools.formatNum(rechargeList[selectIndex].money * 1000)
                  }}
                  VND
                </div>
              </div>
              <!-- <div class="text-dec">
                <p>
                  <span>phí thủ tục:</span>
                  <span>0</span>
                </p>
                <p>
                  <span>Số tiền gửi thực tế:</span>
                  <span>0</span>
                </p>
                <div class="text-danger">
                  <span
                    >={{
                      $tools.formatNum(rechargeList[selectIndex].money)
                    }}</span
                  >
                </div>
              </div> -->
            </div>

            <br />
            <div>
              <button class="btn submit-btn" @click="reCharge()">
                Thanh toán ngay bây giờ
              </button>
            </div>
          </from>
        </div>
        <div class="frame-common" v-if="selectIndex == 4">
          <div class="show-info" style="height:150px;background-color:transparent;">
            <h3>
              <span>Vui lòng nhập mã Code !</span>
            </h3>

            <input class="gift" v-model="giftCode" type="text" placeholder="Vui lòng nhập mã quà tặng" />
          </div>
          <div class="mainBG send canClick middleSize boldSize" @click="codeGift()" style="margin: 30px auto">
            {{ $t("提交") }}
          </div>
        </div>
        <div class="frame-common" v-if="selectIndex == 5" style="position: relative">
          <div class="flexs_r" style="justify-content: center">
            <div v-for="(item, index) in topImgArr" class="canClick" @click="columClick(index)" :key="index" style="position: relative; margin-top: 28px" :style="index > 0 ? 'margin-left:10px' : ''">
              <img :src="item" style="width: 126px" />
              <div v-if="selectTopIndex != index" style="
                  z-index: 9;
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.3);
                "></div>
            </div>
          </div>

          <div class="flexs_r" style="margin-top: 49px">
            <div style="margin-left: -30px">
              <div class="flexs_r_c" style="width: 572px">
                <div style="
                    width: 50%;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    color: #8491a5;
                    font-size: 16px;
                    background-color: #363d4e;
                  ">
                  {{ $t("金额") }}
                </div>
                <div style="
                    width: 50%;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    color: #8491a5;
                    font-size: 16px;
                    background-color: #363d4e;
                  ">
                  {{ $t("兑换") }}
                </div>
              </div>
              <div class="flexs_r_c" v-for="(item, index) in bottomData[selectTopIndex]" style="width: 572px; border-bottom: 1px solid #363d4e">
                <div style="
                    width: 50%;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    color: white;
                    font-size: 14px;
                    background-color: #232733;
                  ">
                  {{ $tools.formatNum(item.Amount) }}
                </div>
                <div style="
                    width: 50%;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    color: white;
                    font-size: 14px;
                    background-color: #232733;
                  ">
                  {{
                    $tools.formatNum(item.Amount - (item.FeePerThousand * item.Amount) / 1000.0)

                  }}
                </div>
              </div>
            </div>
            <div>
              <div class="colum canClick" style="color: white; width: 380px; margin-top: 0" @click="showPicker = !showPicker">
                {{ selectObj ? $tools.formatNum(selectObj.Amount) : $t("选择话费金额") }}

                <img src="@/assets/pc/user/xiala_img.png" style="
                    width: 13px;
                    position: absolute;
                    right: 16px;
                    top: 21.5px;
                  " />

                <div v-if="showPicker" class="selectArea" style="width: 380px; top: 50px; left: 0">
                  <div @click.stop="onConfirm(item)" :style="selectObj == item ? 'background-color: #4a69ff' : ''
                    " v-for="(item, index) in bottomData[selectTopIndex]" class="selectItem middleSize boldSize" :key="item">
                    {{ $tools.formatNum(item.Amount) }}
                  </div>
                </div>
              </div>

              <div style="
                  color: #90a2dc;
                  font-size: 14px;
                  margin-top: 15px;
                  margin-left: 72px;
                ">
                {{ $t('如果选错金额系统会取消') }}

              </div>

              <input class="colum" v-model="cardNum" type="text" placeholder="Vui lòng nhập mã thẻ cào" />
              <input class="colum" v-model="phoneCode" type="text" :placeholder="$t('请输入卡密')" />

              <div class="mainBG send canClick middleSize boldSize" @click="sure()" style="margin: 30px auto">
                {{ $t("提交") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

import i18n from "@/language/i18n";
import {
  nameRule,
  passRule,
  phoneRule,
  realNameRule,
} from "@/utils/vaildate.js";
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const route = useRoute();

let chargeNum = ref(route.params.chargeNum)
bus.off('ChargeNum');
bus.on('ChargeNum', (val) => {
  chargeNum.value = val
})

const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
let rechargeList = reactive([
  {
    name: "BANK",
    class: "bankCard",
    payData: {},
    curBankItem: {},
    bankList: [],
    remark: "",
    money: "",
    accountName: "",
  },
  {
    name: "MOMO",
    class: "momo",
    payData: {},
    money: "",
    payTypeList: [],
    payTypeIndex: 0
  },
  {
    name: "QR",
    class: "qrcode",
    payData: {},
    curBankItem: {},
    bankList: [],
    money: "",
    payTypeList: [],
    payTypeIndex: 0
  },
  {
    name: "LINK",
    class: "common",
    payData: {},
    curBankItem: {},
    bankList: [],
    money: "",
    payTypeList: [],
    payTypeIndex: 0
  },
  {
    name: "CODE",
    class: "code",
    payData: {},
    curBankItem: {},
    bankList: [],
    money: "",
  },
  // {
  //   name: "THẺ CÀO",
  //   class: "phone",
  //   payData: {},
  //   curBankItem: {},
  //   bankList: [],
  //   money: "",
  // },
]);

if (chargeNum.value) {
  rechargeList[0].money = chargeNum.value
  rechargeList[1].money = chargeNum.value
  rechargeList[2].money = chargeNum.value
  rechargeList[3].money = chargeNum.value

}

let topImgArr = reactive([
  require("@/assets/pc/user/yys_1.png"),
  require("@/assets/pc/user/yys_2.png"),
  require("@/assets/pc/user/yys_3.png"),
]);

let loadSucess = ref(false);
let selectIndex = ref(0);
let selectTopIndex = ref(0);
let selectObj = ref(null);
let bottomData = reactive([[], [], []]);
let showPicker = ref(false);
let showPicker0 = ref(false);
let showPicker1 = ref(false);
let showPicker2 = ref(false);


function itemClick(index) {
  selectIndex.value = index;
}

let giftCode = ref("");

let cardNum = ref("");
let phoneCode = ref("");


bus.on('giftCodeResult', (val) => {
  console.log('asdas', val)
  if (val.data.Code == 0) {
    giftCode.value = ''


    ElMessage({
      type: "success",
      message: "Chuyển đổi thành công!",
    });
  } else {

    ElMessage({
      type: "error",
      message: val.data.ErrMsg,
    });

  }
})

function getPayInfo(refreshCode = false) {
  mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
    if (refreshCode) {
      //刷新一下code
      rechargeList[0].remark = data.Data.code;
      return;
    }
    log.info('getPayInfo---', data)
    rechargeList[0].bankList = data.Data.receiveBankList;
    rechargeList[0].curBankItem = rechargeList[0].bankList[0];
    rechargeList[0].remark = data.Data.code;
    for (var i = 0; i < data.Data.vgPayBankList.length; i++) {
      var obj = data.Data.vgPayBankList[i];
      if (obj._id == "bankQr") {
        rechargeList[2].bankList = obj.bankList;
        rechargeList[2].curBankItem = rechargeList[2].bankList[0]//.Name;
      } else if (obj._id == "direct") {
        rechargeList[3].bankList = obj.bankList;
        rechargeList[3].curBankItem = rechargeList[3].bankList[0]//.Name;
      }
    }

    var arr = data.Data.phoneChargeConf;

    for (var obj of arr) {
      obj.text = obj.Amount;

      if (obj.Name == "VIETTEL") {
        bottomData[2].push(obj);
      } else if (obj.Name == "VINAPHONE") {
        bottomData[0].push(obj);
      } else {
        bottomData[1].push(obj);
      }
    }

    for (var i = 0; i < data.Data.payList.length; i++) {
      var obj = data.Data.payList[i];
      if (obj.MethodType == "bank") {
        rechargeList[0].payData = obj;
      } else if (obj.MethodType == "MomoPay") {
        rechargeList[1].payData = obj;
      } else if (obj.MethodType == "bankQr") {
        rechargeList[2].payData = obj;
      } else if (obj.MethodType == "direct") {
        rechargeList[3].payData = obj;
      } else if (obj.MethodType == "naptudong") {
        // rechargeList[5].payData = obj;
      }
    }

    for (let key in data.Data.vgPayConf) {
      let info = data.Data.vgPayConf[key];
      if (key == "momo") {
        rechargeList[1].payTypeList = info;
      } else if (key == "bankQr") {
        rechargeList[2].payTypeList = info;
      } else if (key == "direct") {
        rechargeList[3].payTypeList = info;
      }
    }
    loadSucess.value = true;
  });
}
if (isLogged.value) {
  getPayInfo();
} else {
  bus.on('onConnected', () => {
    getPayInfo()
  })
}
function firstClick(val) {
  rechargeList[selectIndex.value].curBankItem = val
  showPicker0.value = false
  showPicker1.value = false
  showPicker2.value = false

}

function columClick(index) {
  selectTopIndex.value = index;
  selectObj.value = null;
}
function onConfirm(e) {
  selectObj.value = e;
  showPicker.value = false;
}
function copyUrl(text) {
  if (!text) {
    return;
  }
  // 模拟 输入框
  let cInput = document.createElement("input");
  cInput.value = text;
  document.body.appendChild(cInput);
  // 选取文本框内容
  cInput.select();

  // 执行浏览器复制命令
  // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
  // Input要在正常的编辑状态下原生复制方法才会生效
  document.execCommand("copy");

  ElMessage({
    type: "success",
    message: "Sao chép thành công",
  });
  // 复制成功后再将构造的标签 移除
  document.body.removeChild(cInput);
}

function codeGift() {
  if (!giftCode.value.length) {

    return
  }

  mqant.request(topic.giftCode, {
    code: giftCode.value
  }, function (data, topicName, msg) {

  });
}

function sure() {
  if (!selectObj.value) {
    ElMessage({
      type: "info",
      message: "Chọn mệnh giá",
    });
    return;
  }

  if (!this.cardNum.length) {
    ElMessage({
      type: "info",
      message: "vui lòng nhập số tài khoản ngân hàng",
    });
    return;
  }

  if (!this.phoneCode.length) {
    ElMessage({
      type: "info",
      message: "Vui lòng nhập số seri",
    });
    return;
  }
  let curData = rechargeList[selectIndex.value];
  var obj = {
    amount: selectObj.value.Amount,
    methodId: curData.payData.MethodId,
    serial: phoneCode.value,
    code: cardNum.value,
    telco: selectObj.value.Name,
  };

  mqant.request(topic.payCharge, obj, function (res) {
    if (res.Code == 0) {
      ElMessage({
        type: "success",
        message: "Gửi lệnh thành công!",
      });

      phoneCode.value = "";
      cardNum.value = "";
    } else {
      ElMessage({
        type: "error",
        message: res.ErrMsg,
      });
    }
  });
}

function reCharge() {
  let curData = rechargeList[selectIndex.value];
  if (
    curData.money * 1000 < curData.payData.Mini ||
    curData.money * 1000 > curData.payData.Max
  ) {
    ElMessage({
      message:
        "Số tiền nhập khẩu sẽ là" +
        curData.payData.Mini +
        "-" +
        curData.payData.Max,
      type: "warning",
    });
    return;
  }
  if (selectIndex.value == 0) {
    // if (!rechargeList[0].accountName.length) {
    //   ElMessage({
    //     message: "Vui lòng nhập số tài khoản",
    //     type: "error",
    //   });
    //   return;
    // }
  }
  let finalBankName = "";
  let tmpReceiveId = "";
  if (selectIndex.value == 0) {
    finalBankName = curData.curBankItem.BankName;
    tmpReceiveId = curData.curBankItem.Oid;
  } else if (selectIndex.value == 1) {
    finalBankName = '';
  } else {
    finalBankName = curData.curBankItem.Name;
  }
  let obj = {};
  if (selectIndex.value == 0) {
    obj = {
      amount: (curData.money * 1000).toString(),
      methodId: curData.payData.MethodId,
      bankName: finalBankName,
      saveType: "",
      accountName: "default",
      code: rechargeList[0].remark,
      receiveId: tmpReceiveId,
    }
  } else if (selectIndex.value == 1) {
    obj = {
      amount: (curData.money * 1000).toString(),
      methodId: curData.payData.MethodId,
      accountName: 'default',
      payType: rechargeList[selectIndex.value].payTypeList[rechargeList[selectIndex.value].payTypeIndex].payType
    }
  }
  else if (selectIndex.value == 2) {
    obj = {
      amount: (curData.money * 1000).toString(),
      methodId: curData.payData.MethodId,
      accountName: 'default',
      bankName: finalBankName,
      payType: rechargeList[selectIndex.value].payTypeList[rechargeList[selectIndex.value].payTypeIndex].payType
    }
  }
  else if (selectIndex.value == 3) {
    obj = {
      amount: (curData.money * 1000).toString(),
      methodId: curData.payData.MethodId,
      accountName: 'default',
      bankName: finalBankName,
      payType: rechargeList[selectIndex.value].payTypeList[rechargeList[selectIndex.value].payTypeIndex].payType
    }
  }
  if (chargeNum.value) {
    obj.aType = 'TreasureBowl'
  }
  log.info('obj---', obj)
  mqant.request(topic.payCharge, obj, function (data, topicName, msg) {
    if (data.Code == 0) {
      rechargeList[selectIndex.value].money = "";
      if (selectIndex.value == 0) {
        rechargeList[0].accountName = "";
        getPayInfo(true);
      }
      ElMessage({
        message: "Gửi thành công!",
        type: "success",
      });
      if (selectIndex.value >= 1 && selectIndex.value <= 3) {
        // rechargeList[selectIndex.value].payTypeIndex = 0;
        let oWindow = window.open('', '_blank');
        oWindow.location = data.Data.TargetUrl;
      }

    } else {
      ElMessage({
        message: data.ErrMsg,
        type: "error",
      });
    }
  });
}
</script>

<style lang="scss" scoped>
.recharge {
  .tab {
    position: relative;
    padding: 20px 20px;

    > ul {
      background: #232733;
      padding: 10px 20px;
      display: flex;
      width: 100%;
      margin: 0 auto;
      flex-wrap: wrap;
      box-sizing: border-box;

      .selected {
        border: 1px solid #4a69ff;
        background: #292e3b;
      }

      .nolmal {
        background: #363d4e;

        span {
          color: #8e9aad !important;
        }
      }

      li {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        width: calc((100% - 60px) / 4);
        height: 72px;
        margin-right: 20px;
        color: #fff;
        font-size: 14px;
        //background: #433e3e;
        border-radius: 8px;
        box-sizing: border-box;

        list-style: none;
        cursor: pointer;

        .common {
          width: 80px;
          height: 80px;
          margin: 0 0px 0 -20px;
          background-color: #55eb75;
          -webkit-mask: url("@/assets/pc/user/icon_bank.svg") no-repeat center;
          mask: url("@/assets/pc/user/icon_bank.svg") no-repeat center;
          -ms-transform: scale(0.5);
          transform: scale(0.5);
        }

        .bankCard {
          width: 74px;
          height: 51px;
          margin: 0 0px 0 -12px;

          background: url("@/assets/pc/user/recharg_card.png") no-repeat center;
          -ms-transform: scale(0.6);
          transform: scale(0.6);
        }

        .code {
          width: 80px;
          height: 80px;
          margin: 0 0px 0 -20px;

          background: url("@/assets/pc/user/recharg_code.png") no-repeat center;
          -ms-transform: scale(0.6);
          transform: scale(0.6);
        }

        .phone {
          width: 62px;
          height: 84px;
          margin: 0 10px 0 -8px;

          background: url("@/assets/pc/user/shouji_img.png") no-repeat center;
          -ms-transform: scale(0.6);
          transform: scale(0.6);
        }

        .momo {
          width: 40px;
          height: 40px;
          margin-right: 20px;
          background: url("@/assets/pc/user/icon_momo.png") no-repeat center;
          background-size: contain;
        }

        .qrcode {
          width: 80px;
          height: 80px;
          margin: 0 -10px 0 -20px;
          background-color: #f9bb1f;
          -webkit-mask: url("@/assets/pc/user/icon_qrcode.svg") no-repeat center;
          mask: url("@/assets/pc/user/icon_qrcode.svg") no-repeat center;
          -ms-transform: scale(0.5);
          transform: scale(0.5);
        }

        .flag {
          position: absolute;
          bottom: -1px;
          right: -1px;
          height: 27px;
        }
      }

      li:hover {
        border-color: #4a69ff;
      }

      li:nth-child(4) {
        margin-right: 0;
      }
    }
  }

  .choose-pay {
    padding: 20px 20px;

    .tagsDiv {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      background: #232733;
      padding: 10px 20px;

      span {
        min-width: 120px;
        color: #8491a5;
        background: #363d4e;
        border-color: #363d4e !important;
        border-radius: 20px;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        text-align: center;
        border: 1px solid #3c3f43;
        border-bottom: none;
        cursor: pointer;
      }

      .active {
        color: #fff;
        background: #4a69ff;
      }
    }
  }

  .not-lock {
    padding: 20px 20px;

    .body {
      background: #232733;
      border-radius: 10px;
      padding: 0 20px;
      border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

      .show-info {
        position: relative;
        width: 380px;
        height: 100px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #363d4e;

        // border: 3px solid rgba(0, 0, 0, 0);
        // border-color: #4a69ff;
        border-radius: 8px;
        padding: 10px 20px;
        cursor: pointer;

        h3 {
          line-height: 2;
          margin-bottom: 10px;
          font-size: 18px;
          color: #fff;
          font-weight: 500;
          word-break: break-word;
        }

        p {
          margin: 0;
          font-size: 15px;
          color: #fff;

          b {
            color: red;
            font-weight: normal;
          }
        }
      }

      .choose-blank {
        width: calc(50% - 20px);

        > p {
          margin: 0 0 5px;
          font-size: 16px;
          color: #fff;
        }

        .form-control {
          position: relative;
          margin-bottom: 32px;
          display: block;
          width: 100%;
          height: 34px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #fff;
          background-color: #363d4e;
          background-image: none;
          border-radius: 4px;
          // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          // transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        }
      }

      .input-control {
        display: block;
        width: 360px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #fff;
        position: relative;
        z-index: 2;
        margin-bottom: 0;
        padding: 6px 12px;
        background-color: #363d4e;
        background-image: none;
        border-radius: 4px;
        // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        // transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      }

      .frame-blank {
        .from-blank {
          padding: 20px 0;
          color: #fff;
          overflow-y: auto;

          .input-money {
            width: 100%;
            display: flex;

            .left-frame {
              width: calc(50% - 20px);
              // border: 1px solid transparent;
              background-color: #292e3b;
              border-radius: 4px;
              box-shadow: 0 1px 1px rgba(255, 255, 255, 0.05);
              border-color: #ddd;

              ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 10px;

                li {
                  height: 50px;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                  vertical-align: middle;

                  span {
                    font-size: 16px;
                    line-height: 50px;
                  }

                  img {
                    display: inline-block;
                    height: 30px;
                    margin-top: auto;
                    margin-bottom: auto;
                  }
                }
              }
            }

            .right-frame {
              width: calc(50% - 20px);

              .frame-from {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .money {
                  p {
                    font-size: 16px;
                  }

                  .exchange {
                    color: #fff;
                    margin-bottom: 10px;
                  }
                }

                .blank {
                  p {
                    font-size: 16px;
                  }
                }

                .submit {
                  width: 260px;
                  height: 40px;
                  margin: 20px auto;
                  font-size: 18px;
                  color: #fff;
                  background-image: linear-gradient(
                      90deg,
                      #694dff 0%,
                      #595cff 50%,
                      #5063ff 100%
                    ),
                    linear-gradient(#e13c29, #e13c29);
                  background-blend-mode: normal, normal;
                  border-radius: 5px;
                  display: block;
                  text-align: center;
                  white-space: nowrap;
                  cursor: pointer;
                  transition: all 0.3s;

                  span {
                    line-height: 40px;
                  }
                }

                .submit:hover {
                  transform: scale(1.02);
                }
              }
            }
          }
        }
      }

      .frame-common {
        padding: 20px 0;
        color: #fff;
        overflow-y: auto;

        .from-comon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .choose-blank {
            width: 386px !important;
          }

          .text-danger {
            color: #fff;
            font-size: 14px;
          }

          .get-money {
            margin: 0 auto;

            p {
              margin: 0 0 5px;
              font-size: 16px;
            }

            .input-group {
              position: relative;
              display: table;
              border-collapse: separate;
            }

            .text-dec {
              min-width: 0;
              padding: 0;
              margin: 0;
              border: 0;

              p {
                margin: 0 0 5px;
                font-size: 16px;
              }
            }
          }

          .submit-btn {
            width: 360px;
            height: 40px;
            font-size: 18px;
            color: #fff;
            background-image: linear-gradient(
                90deg,
                #694dff 0%,
                #595cff 50%,
                #5063ff 100%
              ),
              linear-gradient(#e13c29, #e13c29);
            background-blend-mode: normal, normal;
            border-radius: 5px;
            border: none;
            transition: all 0.3s;
          }

          .submit-btn:hover {
            // transform: scale(1.02);
          }

          .btn {
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            border: 1px solid transparent;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 1.42857143;
            border-radius: 4px;
            user-select: none;
          }
        }
      }
    }
  }
}

.selectArea {
  white-space: nowrap;
  overflow-y: auto;
  width: 194px;
  height: 160px;
  position: absolute;
  top: 188px;
  left: 215px;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  background-color: #2e3342;

  .selectItem {
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    color: white;
  }

  .selectItem:hover {
    background-color: #4a69ff;
  }
}

.colum {
  margin-left: 72px;
  width: 380px;
  height: 50px;
  background-color: #363d4e;
  border-radius: 10px;
  color: white;
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  margin-top: 44px;
  position: relative;
}

.send {
  width: 200px;
  height: 40px;
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 6px;
  margin-top: 81px;
  margin-left: 200px;
  background-color: #4a69ff;
}

.gift {
  width: 80%;
  padding-left: 10px;
  background-color: #363d4e;
  border-radius: 10px;
  color: white;
  height: 50px;
}
</style>