<template>
  <div class="main" :class="{
    'fold-left-enter-active': leftIn,
    'fold-left-leave-active': leftOut,
    translate50: !showing,
  }">
    <div class="float">
      <img :src="$img('float/float_bg.png')" />
      <div class="layout">
        <div class="title middleSize boldSize">{{ $t('客服') }} 24/7</div>
        <div class="title_watch" @click="onTitleClick"></div>
        <div class="icons">
          <div>
            <img :src="$img('float/cs_kh.png')"
              @click="$act.openBrowser(lobbyInfo.CustomerInfo['customerOnline'], 'cs_online')" />
          </div>
          <!-- <div>
            <img :src="$img('float/cs_fb.png')"
              @click="$act.openBrowser(lobbyInfo.CustomerInfo['customerFaceBook'], 'cs_fb')" />
          </div> -->
          <div>
            <img :src="$img('float/cs_tg.png')"
              @click="$act.openBrowser(lobbyInfo.CustomerInfo['customerTelegram'], 'cs_tg')" />
          </div>
          <div>
            <img :src="$img('float/cs_zalo.png')"
              @click="$act.openBrowser(lobbyInfo.CustomerInfo['customerZalo'], 'cs_zalo')" />
          </div>
          <div>
            <img :src="$img('float/cs_phone.png')"
              @click="showNoticePanel($t('提示'), lobbyInfo.CustomerInfo['customerTel'][0])" />
          </div>
          <div>
            <img :src="$img('float/cs_email.png')"
              @click="showNoticePanel($t('提示'), lobbyInfo.CustomerInfo['customerMail'])" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notice_main" v-if="showNotice">
    <div class="notice">
      <img :src="$img('float/float_panel.png')" />
      <div class="n_content">
        <img :src="$img('float/icon_close.png')" class="n_close" @click="closePanel" />
        <div class="n_title phoneMiddle boldSize">{{ nTitle }}</div>
        <div class="n_msg  phoneMiddle" @click="copyText">
          {{ nMsg }}<img class="icon_copy" :src="$img('float/icon_copy.png')" />
        </div>
      </div>
    </div>
  </div>
  <div class="panel" v-if="showing" @click="
    onTitleClick();
  showNotice = false;
  "></div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Float",
  methods: {
    copyText() {
      this.$tools.copyText(this.nMsg).then(succ => {
        this.$tools.toast("Copied")
      }, err => {
        this.$tools.toast("Can not copy")
      })
    },
    onTitleClick() {
      this.showing = !this.showing;
      // console.log("onTitleClick show:" + this.showing);
      if (!this.showing) {
        this.leftOut = true;
        this.leftIn = false;
      } else {
        this.leftOut = false;
        this.leftIn = true;
      }
    },
    closePanel() {
      this.showNotice = false;
    },
    showNoticePanel(title, msg) {
      this.nTitle = title;
      this.nMsg = msg;
      this.showNotice = true;
    },
  },
  computed: {
    ...mapGetters("global", ['lobbyInfo']),
  },
  data() {
    return {
      leftIn: false,
      leftOut: false,
      showing: false,
      nTitle: "notice",
      nMsg: "importent message",
      showNotice: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon_copy {
  width: 4vw;
  object-fit: contain;
  margin-left: 3%;
}

.panel {
  position: fixed;
  top: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 90;
}

.boldSize {
  font-weight: bold;
}

.notice_main {
  position: fixed;
  // background-color: grey;
  width: 100%;
  height: 100%;
  // padding: 30vh 0 0 22vw;
  z-index: 99;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .notice {
    position: relative;
    width: 57vw;

    .n_close {
      position: absolute;
      top: 1%;
      right: 2%;
      padding: 5%;
      width: 4vw;
    }

    >img {
      width: 57vw;
    }

    .n_content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;

      .n_title {
        margin-top: 4%;
        font-family: SVN-Arial;
        font-weight: bold;
        color: #fff;
      }

      .n_msg {
        padding-top: 2vw;
        font-family: SVN-Arial;
        line-height: 1.53;

        color: #fff;
        margin-top: 4%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.title_watch {
  position: absolute;
  top: 26%;
  left: 0%;
  height: 47%;
  width: 45%;
}

.icons {
  position: absolute;
  top: 3%;
  right: 6%;

  img {
    width: 8vw;
    margin-bottom: 24%;
  }
}

.float {

  //   width: 22vw;
  //   height: 32vh;
  >img {
    width: 22vw;
  }

  position: relative;

  .layout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}

.title {
  color: white;
  transform: rotate(90deg);
  transform-origin: 0% 100%;
  margin: 0% 0 0 18%;
  width: 100%;
  text-align: center;
  position: relative;
  top: -18%;
}

.main {
  position: fixed;
  right: 0;
  top: 30vh;
  z-index: 95;
}

.translate50 {
  -webkit-transform: translate3d(55%, 0, 0);
  transform: translate3d(55%, 0, 0);
}

.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 0.3s;
}

.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: 0.3s;
}

@keyframes fold-left-in {
  0% {
    -webkit-transform: translate3d(55%, 0, 0);
    transform: translate3d(55%, 0, 0);
    /* visibility: visible; */
  }

  /*50% {
      transform: translate3d(50%, 0, 0);
    }*/
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fold-left-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  /*50% {
      transform: translate3d(-50%, 0 , 0);
    }*/
  100% {
    -webkit-transform: translate3d(55%, 0, 0);
    transform: translate3d(55%, 0, 0);
    /* visibility: hidden; */
  }
}

.fold-right-enter-active {
  animation-name: fold-right-in;
  animation-duration: 0.3s;
}

.fold-right-leave-active {
  animation-name: fold-right-out;
  animation-duration: 0.3s;
}

@keyframes fold-right-in {
  0% {
    width: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    /* visibility: visible; */
  }

  /*50% {
      transform: translate3d(50%, 0, 0);
    }*/
  100% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fold-right-out {
  0% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  /*50% {
      transform: translate3d(-50%, 0 , 0);
    }*/
  100% {
    width: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    /* visibility: hidden; */
  }
}
</style>