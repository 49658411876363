import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { req, qs } from "@/utils/request";
import { http, topic } from "@/components/protocol/api";
import {mqant} from "@/components/protocol/mqantlib";
import { bus, busName } from "@/components/core/bus";
import tools from "@/utils/tools.js"
import router from '@/router/index'
import { Toast } from 'vant';
import i18n from '@/language/i18n'
import { ElMessage } from "element-plus";

const reconnectTime = 1000
let reconnect = true
let connecting = false
export const tcp = {
  namespaced: true,
  state: {
    isLogged: false,
    isConnect: false,
    inviteUrl: "",
    chatConf:{
      shareLotteryBet:false
    },
    userInfo: {
      ActivityTotal: 0,
      ChargeCode: "",
      DouDouBet: 0,
      FistChargeTime: "2022-05-04T11:18:08.603Z",
      GiftCode: 0,
      HaveCharge: 1,
      LastChargeTime: "2022-05-04T11:18:08.603Z",
      Oid: "-",
      SafeStatus: 0,
      SumAgentBalance: 0,
      SumBet: 0,
      SumBetCount: 0,
      SumBonus: 0,
      SumCharge: 500000,
      SumDouDou: 0,
      SumOnlineSec: 11734,
      VipLevel: 0,
      WinAndLost: 0
    },
    wallet: {
      AgentBalance: 0,//反水
      BonusBalance: 0,//红利
      Oid: "-",
      SafeBalance: 0,
      UpdateAt: "2022-05-04T11:18:08.591Z",
      VndBalance: 0,
    },
    notice: [

    ],
    logging: true,
    basicInfo: {
      Birthday: "1970-01-01T08:00:00+08:00",
      BonusList: [
      ],
      "DouDouBet": 0,
      LastLoginTime: "2022-07-12T17:42:27.773+07:00",
      Phone: 123456789,
      "RealName": "testAccount",
      "realName":'',
      "DoudouPwd":'',
      "RebateList": [],
      "RegisterTime": "2022-04-02T21:27:39.433+07:00",
      "TodayBetAmount": 0,
      "TodayBetNum": 0,
      "TodayIncome": 0,
    },
  },
  mutations: {
    setLogging (state, logging) {
      state.logging = logging
    },
    setIsConnect (state, connect) {
      state.isConnect = connect
    },
    setLogged (state, logged) {
      state.isLogged = logged
    },
    setLoggedData (state, data) {
      state.inviteUrl = data.inviteUrl
      if(!data.chatConf){
        state.chatConf.shareLotteryBet = false;
      }else{
        state.chatConf = data.chatConf;
      }
      state.userInfo = data.userInfo
      state.wallet = data.wallet
      state.user = data.user
    },
    setChatConf(state,bool){
      state.chatConf.shareLotteryBet = bool;
    },
    setWallet (state, data) {
      state.wallet = data
    },
    setNotice (state, notice) {
      state.notice = notice
      bus.emit('showNoti')
    },
    setBasicInfo (state, info) {
      state.basicInfo = info
    },
  },
  actions: {
    requestBasicInfo ({ commit, dispatch, rootGetters, getters }) {
      mqant.request(topic.userInfo, {}, function (res, topic, msg) {
        log.info('dddd',res)
        // log.info(msg.payloadString)
        if (res.Code == 0) {
          commit("setBasicInfo", res.Data)
          commit("setWallet", res.Data.Wallet)
        }
      })
    },
    connect({ commit, dispatch, rootGetters, getters }) {
      if (this.connecting) {
        log.warn("cur connect state is connecting...")
        return
      }
      reconnect = true
      connecting = true
      return new Promise((resolve, reject) => {
        let client_id = rootGetters["global/uuid"]
        let host = rootGetters["global/lobbyInfo"].TcpInfo.tcp.TcpHost
        let port = rootGetters["global/lobbyInfo"].TcpInfo.tcp.WssPort
        let useSSL = "https:" == document.location.protocol ? true : false;
        // let tcpInfo = rootGetters['user/tcpInfo'];
        if (host.indexOf("wss") == 0) {
          useSSL = true;
        }
        mqant.init({
          host: host,
          port: port,
          client_id: client_id,
          useSSL: useSSL,
          onSuccess: function () {
            connecting = false
            reconnect = true;
            commit("setIsConnect", true);
            // ElMessage.success('综合盘连接上了---------')
            log.info("综合盘长连接成功了----------");
            //默认加入聊天
            //拉取记录，红点不展示
            
            mqant.request(topic.chatJoinGroup, {groupId:"chatRoom"}, function (data, topicName, msg) {
              if(data.Code==0){
                let finalList = [];
                commit("chat/setRedDot", false, { root: true })
                //先拉取聊天历史数据
                mqant.request(topic.chatMsgLog,{ groupId:"chatRoom",size:100},function (data1, topicName1, msg1){
                  // log.info('聊天历史数据',data1)
                  if(data1.Code==0){
                    if(data1.Data.msgList){
                      for(let i = data1.Data.msgList.length - 1; i >= 0 ; i--){
                        finalList.push(data1.Data.msgList[i])
                        // let info = data1.Data.msgList[i];
                        // commit("chat/setRedDot", false, { root: true })
                        // dispatch("chat/dealList", info, { root: true })
                      }
                    }
                    //再拉取系统记录
                    mqant.request(topic.chatMsgSysLog,{ groupId:"chatRoom",size:20},function (data2, topicName2, msg2){
                      // log.info('系统记录',data2)
                      if(data2.Code==0){
                        if(data2.Data.msgList){
                          for(let i = data2.Data.msgList.length - 1; i >= 0 ; i--){
                            finalList.push(data2.Data.msgList[i]);
                            // let info = data2.Data.msgList[i];
                            // commit("chat/setRedDot", false, { root: true })
                            // dispatch("chat/dealList", info, { root: true })
                          }
                        }
                        // finalList.sort((a,b)=>{
                        //   return  a.createAt  >  b.createAt;
                        // })
                        for(let key in finalList){
                          dispatch("chat/dealList", finalList[key], { root: true })
                        }
                        // log.info('所有历史聊天数据',finalList)
                        commit("chat/setRedDot", true, { root: true })
                      }
                    })
                  }

                })
              }
            });
            mqant.on("game/push", function (data2, topic, msg2) {
              dispathcPush(commit, dispatch, rootGetters, getters, data2, topic, msg2)
            });
            resolve(true);
          },
          onConnectionLost: function (responseObject) {
            connecting = false;
            commit("setIsConnect", false);
            // commit("setLogged", false);
            bus.emit(bus.event.showLoading,true);
            log.error("综合盘断开了长连接----------",responseObject);
            // ElMessage.error('综合盘断开了长连接---------')
            if (reconnect) {
              log.info("ws will reconnect in " + reconnectTime / 1000 + " sec...")
             setTimeout(() => {
                  // bus.emit(bus.event.resetGame);
                  dispatch("connect").then(()=>{
                    dispatch("user/autoLogin", {}, { root: true }).then(()=>{
                      bus.emit(bus.event.showLoading,false);
                    })
                  })
              }, reconnectTime);
            }
          },
          onFailure: function () {
            connecting = false;
            commit("setIsConnect", false);
            // commit("setLogged", false);
            bus.emit(bus.event.showLoading,true);
            log.error("综合盘长连接出错了----------");
            // ElMessage.error('综合盘长连接出错了---------')
            if (reconnect) {
              log.error("综合盘长连接出错重连----------");
              setTimeout(() => {
                dispatch("connect").then(succ => {
                  resolve();
                })
            }, reconnectTime);
            }
          },
        });
      })
    },
    disConnect({ commit, dispatch, rootGetters, getters }) {
      commit("setLogged", false)
      dispatch("chat/setRedBagState",{},{root:true}  )
      reconnect = false
      connecting = false
      if (mqant.isConnect()) mqant.disConnect();
    },
    login({ commit, dispatch, rootGetters, getters }) {
      let token = rootGetters['user/token'];
      let clientId = token.Oid;
      return new Promise((resolve, reject) => {
        mqant.request(
          topic.tcpLogin,
          {
            uid: clientId,
            token: token.AccessToken,
          },
          function (res, topic, msg) {
            //TODO
            if (res.Code == 0) {
              dispatch('tcp_lottery/login',{},{root:true}).then(()=>{
                resolve()
                log.info('login-success',res)
                commit("setLoggedData", res.Data)
                commit("setLogged", true)
                dispatch("requestNotice" )
                dispatch("pay/requestPayInfo",{},{root:true}  )
                dispatch("requestBasicInfo" )
                commit("user/setUserInfo", res.Data.user, { root: true })
                bus.emit('onConnected')
                dispatch("chat/setRedBagState",{},{root:true}  )
                bus.emit(bus.event.showLoading,false);
                bus.emit(bus.event.loginState, false);
              },()=>{
                reject()
              });
            } else if (res.Code == 401) {
              reconnect = false;
              reject();
              dispatch("user/doLogout", {}, { root: true })
            }
          }
        );
      })
    },
    requestNotice ({ commit, dispatch, rootGetters, getters }) {
      mqant.request(topic.notice, {}, function (res, topic, msg) {
        //console.log('notice:',res)
        // console.log(msg.payloadString)
        if (res.Code == 0) {

          commit("setNotice", res.Data)
          bus.emit('getNotice')

        }
      })
    },

  },
  getters: {
    logging (state) {
      return state.logging
    },
    isConnect (state) {
      return state.isConnect
    },
    isLogged (state) {
      return state.isLogged
    },
    wallet (state) {
      return state.wallet
    },
    info (state) {
      return state.userInfo
    },
    inviteUrl (state) {
      return state.inviteUrl
    },
    chatConf (state) {
      return state.chatConf
    },
    notice (state) {
      return state.notice
    },
    basicInfo (state) {
      return state.basicInfo
    },
  }
}
function dispathcPush (commit, dispatch, rootGetters, getters, data, topic, msg) {
  switch (data.GameType) {
    case "all":
      dispatchAllAction(commit, dispatch, rootGetters, getters, data, topic, msg)
      break;
    case "chat":
      log.info('chat-msg',data)
      //所有人通知
      if (data.Action === "newChatMsg") {
        dispatch("chat/dealList", data.msg, { root: true })
      }
      //通知自己
      if (data.Action === "focusChatMsg") {
        dispatch("chat/setMyList", data.msg, { root: true })
      }
      break;
    case "pay":
      // console.log(msg.payloadString);
      if (data.Action === "HD_giftCode") {
        console.log('asdrrr')
        bus.emit('giftCodeResult', {
          data: data
        })
      }
      break;
    case "lobby":
      switch (data.Action) {
        case "HD_NotifyJackpot":
          bus.emit('showGIF', {
            data: data.Data
          })
        case "HD_login":
          if (data.Code == 302) {
            bus.emit('loginOut')
            // dispatch("user/doLogout", {}, { root: true })
          }
          break;
      }
      break;
    case "activity":

      if (data.Action == 'HD_ReceiveVipGiftAll') {
        bus.emit('receiveGift', {
          data: data
        })
      } else {
        bus.emit('receiveGift2', {
          data: data
        })
      }
      if (data.Action == 'HD_ReceiveMemberAll') {
        bus.emit('receiveMemberAll', {
          data: data
        })
      }
      if (data.Action == 'HD_StartTurnTable') {
        bus.emit('HD_StartTurnTable', {
          data: data
        })
      }
      break




    default:
    // log.warn(data);
    // console.log(msg2.payloadString)
  }
}

function dispatchAllAction (commit, dispatch, rootGetters, getters, data, topic, msg) {
  switch (data.Action) {
    case "wallet":
      console.log('setWallet---',data.Wallet)
      commit("setWallet", data.Wallet)
      break;
  }
}