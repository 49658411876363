<template>
  <div class="wrap">


    <div class="flexs_r" style="padding-top:20px;">
      <div class="flexs_colum" style="text-align: center; margin-left: 25px;">
        <div @click="selectIndex = 0" :class="selectIndex == 0 ? 'select' : 'nolmal'" class="topBtn  phoneMiddle boldSize"
          style="">{{ $t("红利") }}</div>

      </div>
      <div class="flexs_colum" style="text-align: center; margin-left:20px;">
        <div @click="selectIndex = 1" :class="selectIndex == 1 ? 'select' : 'nolmal'"
          class="topBtn mainBG phoneMiddle boldSize" style="">{{ $t("返水") }}</div>

      </div>
    </div>
    <div v-if="getList().length <= 0" class="no-data-div">
      <img class="no-data-img" :src="$img('user/not_data.png')" alt="">
    </div>

    <div class="list" v-for="(item, index) in getList()" :key="item" style="margin-top: 20px;">
      <div class="title" style="">{{ item.ActivityName }}</div>
      <div class="flexs_sb" style="padding:0 10px;height:45px;">
        <div class="" style="color:rgb(138,159,220);">{{ $t('金额') }}<span style="color:rgb(234,181,25);">{{
          $tools.formatNum(item.Get) }}</span></div>
        <div class="lingqu" @click="onClick(item)">{{ $t("领取") }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { bus, busName } from "@/components/core/bus";
import moment from "moment";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";

export default {
  name: "",
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      selectIndex: 0,
    };
  },
  props: {},
  components: {},

  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
    ...mapGetters("tcp", ["basicInfo", "wallet"]),

  },
  created() {

    var that = this;
    console.log('rrr',this.basicInfo)
  },
  methods: {
    ...mapActions("tcp", ["requestBasicInfo"]),
    getList() {
      return this.selectIndex == 0 ? this.basicInfo.BonusList : this.basicInfo.RebateList
    },
    onClick(item) {
      let that = this;
      if(item.IsNewAct==1){
        
        mqant.request(
        topic.activityAward,
        { ActivityId: item.ActivityId },
        function (res, topic, msg) {
          if (res.Code == 0) {
            that.requestBasicInfo();
            that.$message({
              message: that.$t('领取成功') + '!',
              type: 'success'
            });
           
          }
        }
      );
      }else {
        mqant.request(
        topic.discount,
        { ActivityId: item.ActivityId },
        function (res, topic, msg) {
          if (res.Code == 0) {
            that.requestBasicInfo();
            that.$message({
              message: that.$t('领取成功') + '!',
              type: 'success'
            });
           
          }
        }
      );
      }
    
    },
  },
};
</script>

<style lang="scss" scoped>
.topBtn {
  color: white;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 6px;
}

.item {
  width: 32vw;
  height: 10vw;
  line-height: 10vw;
  background-color: #3a8c38;
  margin-left: 7.5vw;
  position: relative;
  z-index: 99;
  margin-top: 2vw;
}

.item2 {
  width: 32vw;
  height: 10vw;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 10vw;
  margin-left: -1vw;
  margin-top: 2vw;
  position: relative;
  background-color: rgb(44, 44, 44);
  z-index: 1;
}

.lingqu {
  //width: 64px;
  padding: 0 5px;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  background: linear-gradient(to right, rgb(110, 74, 255), rgb(80, 100, 255));
  color: white;
  text-align: center;
  border-radius: 5px;
}

.select {
  color: white;
  background: linear-gradient(to right, rgb(110, 74, 255), rgb(80, 100, 255));

}

.nolmal {
  background-color: #363d4e;
  color: #8491a5;
}

.wrap {
  padding-top: 50px;
  position: relative;

  .no-data-div {
    margin-top: 100px;
    text-align: center;

    img {
      width: 348px;
      height: 298px;
    }
  }
}

.list {
  border-radius: 4px;
  margin: 0 25px;
  background-color: #232733;

}

.title {
  height: 32px;
  background-color: #363d4e;
  color: white;
  padding-left: 10px;
  line-height: 32px;
}
</style>
