<template>
        <div>
                <h1 style="text-align: center;"><strong>ĐIỀU KIỆN KHUYẾN MÃI</strong></h1>
                <p><span style="">1. Chương trình khuyến mãi chỉ dành cho thành
                                viên có một tài khoản duy nhất tại giải trí i5bet. Người trong gia đình có cùng địa chỉ nhà, số
                                điện thoại, thiết bị, số tài khoản ngân hàng hoặc địa chỉ IP trên mạng Internet sẽ không được
                                tham gia chương trình. Thành viên muốn tham gia khuyến mãi nên hạn chế sử dụng mạng 3G hoặc 4G,
                                internet công cộng để không vi phạm quy định IP. Mỗi khách thành viên, mỗi địa chỉ, mỗi email,
                                mỗi số điện thoại, mỗi tài khoản ngân hàng chỉ có thể tham gia khuyến mãi một lần trong thời
                                gian chương trình diễn ra. i5bet có quyền tịch thu tiền thưởng nếu bất kỳ khách hàng hay nhóm
                                khách hàng nào vi phạm.</span></p>
                <p><span style="">2. Doanh thu cược phải được hoàn thành trong vòng
                                30 ngày, nếu không hệ thống sẽ thu hồi lại tiền thắng và tiền thưởng của tất cả các khuyến mãi
                                hiện có tại i5bet.</span></p>
                <p><span style="">3. Các chương trình khuyến mãi chỉ mang tính chất
                                tham khảo. i5bet có quyền thay đổi, chỉnh sửa nội dung, điều khoản, chấm dứt chương trình
                                khuyến mãi hoặc giới hạn quyền tham gia bất cứ chương trình khuyến mãi nào của khách hàng mà
                                không cần thông báo trước. i5bet dựa vào các Điều kiện và điều khoản để áp dụng các quy
                                tắc.</span></p>
                <p><span style="">4. i5bet không chấp nhận những hành vi thiếu
                                trung thực nhằm gian lận khuyến mãi. Chúng tôi có quyền khóa tài khoản và tịch thu tiền thưởng
                                của bất kỳ khách hàng hay nhóm khách hàng nào vi phạm.</span></p>
                <p><span style="">5. i5bet có đội ngũ chuyên nghiệp chuyên kiểm
                                tra việc lạm dụng và gian lận tất cả các sản phẩm khuyến mãi. Nếu phát hiện bất kỳ dấu hiệu lạm
                                dụng và gian lận nào, Chúng tôi có toàn quyền thu hồi các khoản tiền thưởng và tiền thắng cược
                                liên quan từ tài khoản đó cùng các tài khoản khác có liên quan. Đối với những trường hợp nghiêm
                                trọng, sẽ đóng băng tài khoản vĩnh viễn mà không cần thông báo hoặc giải thích. Việc xác định
                                như thế nào là lạm dụng tiền thưởng sẽ dựa trên việc kiểm tra chặt chẽ những bằng chứng rõ ràng
                                về lạm dụng và gian lận tiền thưởng như sau: Cược 2 bên (cược đối lập). Ví dụ: Trong Baccarat
                                cùng lúc cược nhà cái và nhà con. Trong Roulette cùng lúc cược đỏ và đen…;Gian lận có tổ chức,
                                theo nhóm; Đặt cược tiền thưởng vào các trò chơi bị loại trừ; Sử dụng nhiều tài khoản để truy
                                cập; Cược chéo tài khoản ; Lạm dụng chênh lệch tỷ lệ kèo giữa các trang cá cược; Tiền thưởng chỉ
                                được nhận một lần duy nhất (ví dụ khuyến mãi dành cho thành viên mới)</span></p>
                <p><span style="">6. Khi cần xác minh thông tin khi gửi hoặc rút
                                tiền, thành viên có thể được yêu cầu cung cấp bằng chứng về thông tin cá nhân kèm chứng minh thư
                                nhân dân, địa chỉ, số điện thoại, biên lai gửi tiền cùng lịch sử cược. Trong vòng 72 giờ nếu vẫn
                                không thể xác minh được thì tiền thưởng và tiền thắng cược có liên quan khác có thể bị thu
                                hồi.</span></p>
                <p><span style="">7. Trong thể thao: nếu thành viên cược các vé có
                                tỷ lệ cược thấp hơn tỷ lệ tiêu chuẩn Châu Âu 1.5; Hồng Kông 0.5; Malaysia 0.5; Indonesia -2.0;
                                hoặc trận đấu hủy, hòa, cược vô hiệu hoặc cược đối lập sẽ không tính vào doanh thu cược của
                                khuyễn mãi. Những trò chơi slot không tính vào doanh thu cược bao gồm: Table Games, Video Poker,
                                Roulette, Pontoon, Craps, live games, Pai Gow poker, texas Hold’em. Trong slot, khi thành viên
                                sử dụng tiền gửi để quay thưởng, khi quay trúng Jackpot sẽ được coi là hợp lệ</span></p>
                <p><span style="">8. Các khuyến mãi tiền thưởng khác không áp dụng
                                cho sản phẩm Xổ Số. Nếu quý khách lấy tiền thưởng để tham gia Xổ Số sẽ không được tính là hợp
                                lệ, và tất cả tiền thắng của Xổ Số sẽ được thu hồi.</span></p>
                <p><span style="">9. Thông tin cá nhân của thành viên tại i5bet
                                phải trùng khớp với thông tin cá nhân của thành viên trong tài khoản ngân hàng. Nếu có sự sai
                                sót, khác biệt hoặc giả mạo trong thông tin có thể dẫn đến việc tài khoản thành viên bị chúng
                                tôi đóng băng vĩnh viễn.</span></p>
                <p><span style="">10. Tất cả tiền thưởng và tiền thắng cược đều
                                không được phép chuyển nhượng lại cho tài khoản thành viên khác.</span></p>
                <p><span style="">11. Trong trường hợp có tranh chấp phát sinh liên
                                quan đến khuyến mãi, quyền quyết định cuối cùng thuộc về i5bet . Tuy nhiên, chúng tôi luôn cố
                                gắng hết sức để xử lý theo cách tốt nhất cho 2 bên. i5bet sẽ xem xét mọi khiếu nại của thành
                                viên hoặc bên thứ ba liên quan trước khi đưa ra quyết định cuối cùng.</span></p>
                <p><span style="">12. Thành viên tham gia khuyến mãi tại i5bet
                                được xem như đã đồng ý với những điều khoản được nêu ở trên. Đồng thời không được quyền làm tổn
                                hại đến uy tín của trang web, đại diện pháp luật, chi nhánh, cơ quan cao cấp, nhân viên và đại
                                lý của i5bet dù thông qua bất cứ hình thức trực tiếp hay gián tiếp nào.</span></p>
                <p><span style="">13. i5bet có quyền hủy hoặc điều chỉnh bất kỳ
                                chương trình khuyến mãi nào mà không cần thông báo trước.</span></p>
                <p><span style="">14. i5bet có quyền đưa ra quyết định và giải
                                thích cuối cùng trong mọi trường hợp. </span></p>
                <p> </p>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>