<template>
  <div class="recharge">
    <div class="nav">
      <van-nav-bar title="Nạp tiền">
        <!-- <template #left>
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow" @click="comeBack()"></i>
        </template> -->
        <template #right>
          <span @click="toBill()" class="rightText iconfont icon-icon_history"></span>
        </template>
      </van-nav-bar>
    </div>
    <div class="scrollList">
      <van-loading v-if="loading" class="loading" type="spinner" />
      <div v-if="!loading" class="wrapper-tontent">
        <div class="card">
          <p class="channelTitle"> Chọn kênh thanh toán </p>
          <div class="newTop">
            <div v-for="(item, index) in payList" class="pay-item" :class="index == curPaySelectIndex ? 'is-select' : ''" @click="curPaySelectIndex = index">
              <img :src="$img('userCenter/' + item.icon, 'mobile')" alt="">
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
        <BankView v-if="curPaySelectIndex == 0" :data="payData" />
        <MoMoView v-if="curPaySelectIndex == 1" :data="payData" />
        <QrCodeView v-if="curPaySelectIndex == 2" :data="payData" />
        <LinkView v-if="curPaySelectIndex == 3" :data="payData" />
        <RedeemCodeView v-if="curPaySelectIndex == 4" :data="payData" />
        <PhoneView v-if="curPaySelectIndex == 5" :data="payData" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import BankView from "@/views/mobile/components/userCenter/recharge/BankView.vue"
import MoMoView from "@/views/mobile/components/userCenter/recharge/MoMoView.vue"
import QrCodeView from "@/views/mobile/components/userCenter/recharge/QrCodeView.vue"
import LinkView from "@/views/mobile/components/userCenter/recharge/LinkView.vue"
import RedeemCodeView from "@/views/mobile/components/userCenter/recharge/RedeemCodeView.vue"
import PhoneView from "@/views/mobile/components/userCenter/recharge/PhoneView.vue"

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
let loading = ref(true);
let payData = ref({});
let curPaySelectIndex = ref(0);
let payList = reactive([
  { icon: 'mb_yhk_img.png', text: 'BANK' },
  // { icon: 'icon_momo.png', text: 'MOMO' },
  // { icon: 'icon_saoma.png', text: 'QR' },
  // { icon: 'icon_yhcz.png', text: 'LINK' },
  // { icon: 'icon_duihuan.png', text: 'CODE' },
  // { icon: 'icon_shouji.png', text: 'THẺ CÀO' },

])
// bus.emit(bus.event.showFooter, false);
if (isLogged.value) {
  getPayInfo()
}
bus.on('onConnected', () => {
  getPayInfo()
})
function getPayInfo() {
  mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
    if (data.Code == 0) {
      payData.value = data.Data;
      log.info('payData', data.Data);
      loading.value = false;
    } else {
      loading.value = true;
    }
  });
}

function toBill() {
  localStorage.removeItem('ChargeNum')
  router.push({ path: '/billRecord' });


}

function comeBack() {
  router.go(-1);
  // bus.emit(bus.event.showFooter, true);
}
onBeforeUnmount(() => {
  // bus.emit(bus.event.showFooter, true);
})
</script>

<style lang="scss" scoped>
.recharge {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
  background-color: #293356;

  .nav {
    :deep(.van-nav-bar) {
      box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, 0.15);
      line-height: 5.866667vw;
      text-align: center;
      background-color: #293356;
      user-select: none;

      .van-nav-bar__content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 13.333333vw;
      }

      .van-nav-bar__left {
        font-size: 5.333333vw;
      }

      .van-nav-bar__arrow {
        margin-right: 1.066667vw;
        font-size: 5.333333vw;
      }

      .van-nav-bar__title {
        max-width: 60%;
        margin: 0 auto;
        color: #fff;
        font-weight: 500;
        font-size: 4.266667vw;
      }

      .van-icon {
        color: #fff;
      }

      .van-nav-bar__left,
      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 4.266667vw;
        font-size: 3.733333vw;
        cursor: pointer;

        .rightText {
          font-size: 5.333333vw;
          color: #fff;
        }
      }
    }
  }

  .scrollList {
    overflow-y: auto;
    position: absolute;
    top: 13.333333vw;
    bottom: 0;
    left: 0;
    right: 0;

    .loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .wrapper-tontent {
      font-size: 3.733333vw;
      color: #fff;
      padding-bottom: 5.333333vw;
      padding-left: 4.266667vw;
      padding-right: 4.266667vw;

      .card {
        padding: 4.266667vw 0 0;
        display: table;
        width: 100%;

        .channelTitle {
          height: 4.8vw;
          font-family: PingFangSC-Regular;
          font-size: 3.466667vw;
          color: #fff;
          font-weight: 400;
          margin-bottom: 2.666667vw;
        }

        :deep(.van-cell) {
          background-color: #1e2646;
          border-radius: 8px;
          font-size: 12px;
          --van-field-label-color: #424f81;
          --van-field-input-text-color: #fff;
        }
      }

      .newTop {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .pay-item {
          width: 166px;
          height: 60px;
          display: flex;
          align-items: center;
          background-color: #2f395f;
          border-radius: 8px;
          padding: 5px;
          border: solid 1px #2f395f;

          img {
            width: 44px;
            height: 44px;
            margin: auto 7px;
          }

          span {
            font-size: 12px;
            color: #fff;
            word-break: keep-all;
            word-wrap: break-word;
          }
        }

        .is-select {
          background-color: #394674;
          box-shadow: 0px 1px 1.5px 0px rgba(0, 0, 0, 0.4);
          border-radius: 8px;
          border: solid 1px #7989bd;
        }
      }
    }
  }
}
</style>
