<template>
    <div class="main" id="main">
        <router-view />
        <Footer v-if="$route.path != '/lottery'" />
        <Login v-if="loginState" />
        <Register v-if="registerState" />
        <Notice v-if="isLogged && noticeState && (lobbyInfo.LobbyNotice && lobbyInfo.LobbyNotice.length > 0)" show="true" />
    </div>
</template>

<script>


export default {


    created() {

    },

    watch: {
        $route(to, from) {
            document.documentElement.scrollTop = 0;
            document.getElementById('main').scrollTop = 0
        }
    },
    methods: {


    }
}
</script>

<script setup>
import Register from '@/views/pc/components/Register.vue'
import Login from '@/views/pc/components/Login.vue'
import Notice from '@/views/pc/components/Notice.vue'
import Footer from '@/views/pc/components/Footer.vue'
import { useGetters, useActions } from '@/store/hooks/storeState/index'
import { ref } from 'vue';
import { bus } from '../../../components/core/bus';
const { isLogged } = useGetters("tcp", ["isLogged"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let [loginState, registerState, noticeState] = [ref(false), ref(false), ref(false)];
bus.on(bus.event.loginState, (bool) => {
    loginState.value = bool;
})
bus.on(bus.event.registerState, (bool) => {
    registerState.value = bool;
})
bus.on(bus.event.noticeState, (bool) => {
    noticeState.value = bool;
})
</script>

<style lang="scss" scoped>
.main {
    color: #fff;
    overflow-y: scroll;
    scroll-behavior: smooth;
    background: #1e222c !important;
    height: calc(100% - 140px) !important;
}
</style>