<template>
        <div>
                <h1 style="text-align: center;"><strong>QUYỀN RIÊNG TƯ</strong></h1>
                <p><span style="">Chúng tôi tôn trọng quyền riêng tư của Thành viên
                                và bảo mật an toàn dữ liệu luôn là ưu tiên hàng đầu của chúng tôi. i5bet sẽ không bao giờ tiết
                                lộ thông tin cá nhân của Thành viên cho bên thứ ba trừ khi nhận được thông tin yêu cầu phán
                                quyết của tòa án. Chúng tôi có quyền cung cấp thông tin cá nhân cần thiết cho nhà cung cấp dịch
                                vụ thanh toán cơ bản và các tổ chức tài chính, bảo hiểm thông qua trang web để hoàn thành yêu
                                cầu thanh toán. Tất cả thông tin cá nhân do Thành viên cung cấp sẽ được gửi qua cổng an toàn (
                                chuẩn mã hóa 128 bit SSL) và được lưu trữ trong môi trường tuyệt mật chống lại sự xâm nhập từ
                                bên ngoài. Tất cả dữ liệu trong và ngoài đều bị hạn chế nghiêm ngặt và theo dõi chặt chẽ. i5bet
                                và các đối tác của chúng tôi sẽ gửi cho Thành viên thông báo về khuyến mại mà Thành viên có thể
                                quan tâm qua thư. i5bet không tiết lộ bất kì thông tin Thành viên cho bất cứ bên thứ ba nào vì
                                đó là mục đích của chính sách bảo mật của chúng tôi. Nếu không có sự cho phép bằng văn bản rõ
                                ràng của i5bet, không ai được phân phối, thay đổi, sao chép, tái bản, sử dụng nội dung của
                                i5bet hoặc nhân bản máy chủ không phải là i5bet.</span></p>
                <p> </p>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>