<template>
  <template v-if="isFactory">
    <router-view />
  </template>
  <template v-else>
    <Splash v-if="loading" />
    <router-view v-if="hasLoad" />
  </template>
  <div class="app-system-tip">
    <el-dialog v-model="systemTipShow" title="Thông báo hệ thống" center :close-on-click-modal="false">
      <span>
        Kính gửi các thành viên, 88bet đã chính thức sáp nhập vào i5bet, các thành viên đã sở hữu tài khoản 88bet ban đầu
        vẫn có thể đăng nhập và đặt cược trên i5bet.
        hy vọng rằng tất cả các thành viên có thể có được trải nghiệm chưa từng có trong phiên bản mới!
        Vui lòng tìm kiếm trang web chính thức: <a href="https://i5bet.com" @click="systemTipShow = false">i5bet.com</a>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="centerDialogVisible = false">Cancel</el-button> -->
          <el-button type="primary" @click="systemTipShow = false">
            Xác nhận
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script setup>
import Splash from "@/views/pc/components/Splash.vue";
import Load from "@/views/pc/components/Load.vue";
import bus from '@/utils/bus'
import tools from "@/utils/tools";
import { useGetters, useActions, useMutations } from '@/store/hooks/storeState/index'
import { ref, nextTick } from 'vue';
import { onMounted } from "vue";
import log from "./utils/logger";
import { useRouter, useRoute } from "vue-router";
import { topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { Toast } from "vant";
import store from '@/store'
import { ElMessage } from "element-plus";
const { isLogged } = useGetters("tcp", ["isLogged"]);
const { getLobbyInfo } = useActions('global', ['getLobbyInfo'])
const { init } = useActions("global", ["init"]);
const { connect_lottery } = useActions("tcp_lottery", ["connect_lottery"]);
const { connect } = useActions("tcp", ["connect"]);
const { autoLogin } = useActions("user", ["autoLogin"]);
const { setOutToken } = useMutations("user", ["setOutToken"]);
const { token, outToken } = useGetters("user", ["token", "outToken"]);


const { resetGame } = useActions("global", ["resetGame"]);
const router = useRouter();
const route = useRoute();
let isFactory = ref(false);//是否厂商的标识
let hasLoad = ref(false);
let loading = ref(false);
const systemTipShow = ref(false);

if (getToken()) {
  setOutToken(getToken())
}

if (window.location.href.indexOf("navigator") >= 0) {
  isFactory.value = true;
} else {
  isFactory.value = false;
  loading.value = true;
  startGame();
  bus.off(bus.event.showLoading);
  bus.on(bus.event.showLoading, (bool) => {
    loading.value = bool;
  })
  bus.off(bus.event.resetGame);
  bus.on(bus.event.resetGame, () => {
    hasLoad.value = false;
    startGame();
  })
}


onMounted(() => {
  visibilitychange();
})
/** 浏览器窗口监听 */
const visibilitychange = () => {
  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'hidden') {
      log.error('离开了本网页---')
    } else {
      // let getIsLogged = store.getters["tcp/isLogged"];
      // log.error('离开了本网页---', isLogged.value, getIsLogged, mqant.isConnect())
      // ElMessage.info(`回来了本网页---,登录状态1为${isLogged.value},登录状态2为${getIsLogged}链接状态${mqant.isConnect()}`);
      log.error('回来了本网页---')
      if (isLogged.value) {
        if (mqant.isConnect()) {
          mqant.request(topic.tcpTest, {}, function (data, topicName, msg) {
            log.info('topic.tcpTest---', data)
            if (data.Code == 0) {
            } else {
              loading.value = true;
              resetGame();
            }
          });
        } else {
          loading.value = true;
          resetGame();
        }
      } else {
        if (!token.value && !outToken.value) {
          loading.value = true;
          resetGame();
        }
      }
    }
  });
}

function getToken() {
  let url = window.location.href
  let token = ''
  var arr = url.split('?')
  if (arr.length > 1) {
    let p = url.split('?')[1].split('#')[0]
    if (p) {
      let keyValue = p.split('&');
      let obj = {};
      for (let i = 0; i < keyValue.length; i++) {
        let item = keyValue[i].split('=');
        let key = item[0];
        let value = item[1];
        obj[key] = value;
      }
      token = obj.token
    }
  }


  return token
}

function startGame() {
  getLobbyInfo().then(() => {
    hasLoad.value = true;


    init().then(() => {
      //综合盘连接
      connect().then(() => {
        if (!isLogged.value) {
          autoLogin().then(() => {
            log.info('autoLogin111')
            loading.value = false;
            linkChangeTip();
          }, (err) => {
            log.info('autoLogin222')
            router.push('/');
            loading.value = false;
            linkChangeTip();
          });
        }
        //彩票连接
        connect_lottery();
      });
    })
  });
}

function linkChangeTip() {
  if (tools.platform() == 'android') {
    return;
  }
  if (window.location.href.indexOf("88bet") >= 0
    || window.location.href.indexOf("subet") >= 0) {
    systemTipShow.value = true;
  } else {
    systemTipShow.value = false;
  }
}

</script>
<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  position: relative;
}

.app-system-tip {
  position: relative;
  z-index: 99999;

  .el-dialog {
    width: 90%;
    background: #292e3b;
    color: #fff;
    --el-dialog-margin-top: 30vh;

    .el-dialog__title {
      color: #fff;
    }

    .el-dialog__body {
      color: #fff;
    }

    .el-dialog__close {
      color: #fff;
    }

    span {
      line-height: 1.2;

      a {
        color: aqua;
        transition: all 0.2s;
      }

      a:hover {
        transform: scale(1.2);
      }
    }
  }
}

@media (min-width: 768px) {
  .app-system-tip {
    .el-dialog {
      width: 700px;
    }
  }
}
</style>
