<template>
  <div class="momo">
    <div class="card">
      <p class="channelTitle">Số tiền nạp</p>
      <div class="less-pay">
        <span>Chuyển khoản ngân hàng</span>
        <span>Giới hạn nạp tối thiểu <span style="color:red;">&nbsp;{{ $tools.formatNum2k(obj.Mini) }}~{{
          $tools.formatNum2k(obj.Max) }} VND(K)</span></span>
      </div>
    </div>
    <div v-if="obj.payTypeList.length > 1" class="card">
      <p class="channelTitle"> Chọn phương thức thanh toán </p>
      <div class="newTop">
        <div v-for="(item, index) in obj.payTypeList" class="newTopItem"
          :class="obj.payTypeIndex == index ? 'active' : ''" @click="obj.payTypeIndex = index">
          <img class="icons" src="@/assets/mobile/userCenter/pay_img.png" alt="">
          {{ item.payType }}
        </div>
      </div>
    </div>
    <div class="card">
      <p class="channelTitle">Chọn nhanh tiền nạp</p>
      <!-- <div class="moneyGrid">
        <van-grid :border="false" :column-num="5">
          <van-grid-item v-for="(item, index) in gridList" @click="itemClick(item)">
            <div class="moneyList">{{ item.name }}</div>
          </van-grid-item>
        </van-grid>
      </div> -->
      <div class="input-class">
        <van-field v-if="ChargeNum > 0" v-model="obj.money" type="number" readonly placeholder="Vui lòng nhập số tiền"
          :border="false" />

        <van-field v-else v-model="obj.money" type="number" placeholder="Vui lòng nhập số tiền" :border="false" />
        <div class="input-money">= {{ Number(obj.money) * 1000 }} VND</div>

      </div>
      <div class="card">
        <div class="pay-btn" @click="onSubmit">
          <span> Nạp ngay </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let gridList = reactive([
  {
    name: '50K',
    number: 50,
  },
  {
    name: '100K',
    number: 100,
  },
  {
    name: '500K',
    number: 500,
  },
  {
    name: '1M',
    number: 1000,
  },
  {
    name: '5M',
    number: 5000,
  },
  {
    name: '10M',
    number: 10000,
  },
  {
    name: '50M',
    number: 50000,
  },
  {
    name: '100M',
    number: 100000,
  },
  {
    name: '300M',
    number: 300000,
  },
  {
    name: '500M',
    number: 500000,
  }
])
let showPicker = ref(false);
let obj = reactive({
  money: '',
  Mini: 0,
  Max: 0,
  MethodId: '',
  payTypeList: [],
  payTypeIndex: 0
})
function itemClick(item) {
  if (ChargeNum.value > 0) {
    return
  }
  obj.money = item.number;
}

let ChargeNum = ref(0);

onMounted(() => {
  if (Object.keys(props.data).length <= 0) return;

  if (localStorage.getItem('ChargeNum')) {
    ChargeNum.value = localStorage.getItem('ChargeNum')
    obj.money = localStorage.getItem('ChargeNum')
  }

  for (let key in props.data.payList) {
    let info = props.data.payList[key];
    if (info.MethodType == "MomoPay" && info.Merchant == 'VgPay') {
      obj.Mini = info.Mini;
      obj.Max = info.Max;
      obj.MethodId = info.MethodId;
    }
  }
  for (let key in props.data.vgPayConf) {
    let info = props.data.vgPayConf[key];
    if (key == "momo") {
      obj.payTypeList = info
    }
  }
})

function onSubmit() {
  if (!obj.money) {
    Toast.fail('Vui lòng nhập số tiền');
    return;
  }
  if (Number(obj.money) * 1000 < obj.Mini || Number(obj.money) * 1000 > obj.Max) {
    Toast.fail('Số tiền nhập khẩu sẽ là' + obj.Mini + '-' + obj.Max);
    return
  }
  let objSubmit = {
    amount: Number(obj.money) * 1000,
    methodId: obj.MethodId,
    accountName: 'default',
    payType: obj.payTypeList[obj.payTypeIndex].payType
  }
  if (ChargeNum.value > 0) {
    objSubmit.aType = 'TreasureBowl'
  }
  let oWindow = window.open('', '_blank');
  log.info('objSubmit', objSubmit)
  mqant.request(topic.payCharge, objSubmit, function (data, topicName, msg) {
    if (data.Code == 0) {
      oWindow.location = data.Data.TargetUrl;
      obj.money = '';
      Toast.success(data.ErrMsg)
    } else {
      Toast.fail(data.ErrMsg)
    }
  })
}
</script>

<style lang="scss" scoped>
.momo {
  .card {
    padding: 4.266667vw 0 0;
    display: table;
    width: 100%;

    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    .newTop {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .newTopItem {
        flex: 1;
        width: 48%;
        min-width: 48%;
        max-width: 48%;
        margin-right: 4%;
        margin-bottom: 2.933333vw;
        display: flex;
        align-items: center;
        padding: 1.333333vw;
        background: #fff;
        border-radius: 2.133333vw;
        border: 1px solid #fff;
        font-size: 3.2vw !important;
        word-break: keep-all;
        word-wrap: break-word !important;

        .icons {
          margin-right: 2.133333vw;
          width: 11.733333vw !important;
          height: 11.733333vw !important;
          border-radius: 1.066667vw;
        }
      }

      .newTopItem:nth-child(2n) {
        margin-right: 0;
      }

      .active {
        background: #4488cc;
        border: 1px solid #4488cc;
        box-shadow: 0 0.533333vw 1.066667vw 0 rgba(0, 0, 0, .2);
        border-radius: 2.133333vw;
        color: #fff;
      }
    }



    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  }

  .less-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;
    font-size: 12px;
    width: 100%;
    height: 82px;
    padding: 21px 12px;
    background-color: #2f395f;
    border-radius: 8px;

    >span {
      color: #fff;
    }

    span+span {
      color: #8a9fdc;
    }
  }

  .moneyGrid {
    :deep(.van-grid) {
      flex-wrap: wrap;

      .van-grid-item__content {
        padding: 0;
        font-size: 3.2vw;
        background-color: transparent;

        .moneyList {
          height: 8.533333vw;
          background: #2f395f;
          color: #90a2dc;
          border-radius: 1.066667vw;
          line-height: 8.533333vw;
          width: 90%;
          padding: 0 2.666667vw;
          margin-bottom: 2.666667vw;
          text-align: center;
        }
      }
    }
  }

  .input-class {
    .input-money {
      margin: 9px;
      font-size: 10px;
      color: #fff;
    }
  }

  .pay-btn {
    position: relative;
    height: 49px;
    background-image: linear-gradient(90deg,
        #6e4aff 17%,
        #5064ff 100%),
      linear-gradient(#0e1525,
        #0e1525);
    background-blend-mode: normal,
      normal;
    border-radius: 25px;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
