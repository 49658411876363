<template>
    <div class="footer">
        <div class="game-wp">
            <div class="game-logo-wp">
                <div class="game-logo" v-for="(item, index) in 21">
                    <img :src="$img('footer/icon_factory/cs' + (index + 1) + '.png')" alt="">
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="spec spec1">
            Cờ bạc có thể gây nghiện, hãy chơi có trách nhiệm. Để biết thông tin về các biện pháp hỗ trợ, vui lòng truy cập
            trang trợ giúp của chúng tôi.
        </div>
        <div class="spec spec2">
            Chúng tôi được cấp giấy phép hợp pháp do Ủy ban Giám sát GC（GamblingCommission）của Vương quốc Anh (MGA) và
            (PAGCOR) do Chính phủ Philippines cấp. Công ty đăng ký tại Quần đảo Virgin thuộc Anh, được các hiệp hội quốc tế
            công nhận. Vui lòng đảm bảo bạn đủ 18 tuổi trở lên trước khi đăng ký tham gia trò chơi!
        </div>
        <div class="imgs">
            <img :src="$img('footer/img_show_1.png')" alt="">
            <img :src="$img('footer/img_show_2.png')" alt="">
            <img :src="$img('footer/img_show_3.png')" alt="">
            <img :src="$img('footer/img_show_4.png')" alt="">
        </div>
        <div class="contactUs">
            <div @click="openHelp(0)"> i5bet</div>
            <span>|</span>
            <div @click="openHelp(7)"> QUYỀN RIÊNG TƯ </div>
            <span>|</span>
            <div @click="openHelp(9)"> LIÊN HỆ </div>
        </div>
        <div class="copyright">
            Copyright @ i5bet V{{ version }}
        </div>
    </div>
</template>

<script setup>
import Footer from '@/views/pc/components/Footer.vue'
import { useGetters, useActions } from '@/store/hooks/storeState/index'
import { useRouter, useRoute } from "vue-router";
const { version } = useGetters("global", ["version"]);
const router = useRouter();
const openHelp = (index) => {
    router.push({
        path: "/help",
        query: {
            index: index,
        },
    });
}
</script>

<style lang="scss" scoped>
.footer {
    background: #202225;
    border-top: none;
    width: 100%;
    padding-bottom: 20px;

    .game-wp {
        height: auto !important;

        .game-logo-wp {
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-top: 24px;
            width: 1400px;

            .game-logo {
                margin-bottom: 10px;
                // width: 209px;
                height: 60px;
                text-align: center;
                transition: all .3s;
                cursor: pointer;

                img {
                    height: 100%;
                }
            }

            .game-logo:hover {
                transform: scale(1.2);
            }
        }
    }

    .line {
        height: 2px;
        background: rgba(144, 162, 220, .2);
        margin: auto;
        width: 1163px;
        opacity: .5;
        border-radius: 2px;
    }

    .spec {
        color: #8491a5;
        opacity: 1;
        background: 0 0;

        margin: auto;
        padding: 4px 0;
        width: 1100px;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
    }

    .spec1 {
        margin-top: 12px;
    }

    .spec2 {
        margin-bottom: 6px;
    }

    .imgs {
        background: 0 0;
        display: flex;
        justify-content: center;
        margin: 0 0 10px;

        img {
            opacity: .6;
            margin: 0 30px;
            height: 70px;
        }
    }

    .contactUs {
        color: #8491a5;
        opacity: 1;
        background: 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        padding-bottom: 16px;
        width: 1400px;
        height: 36px;
        line-height: 20px;
        font-size: 14px;
        cursor: pointer;

        div {
            padding: 0 36px;
        }

        div:hover {
            color: #e9cfa4;
        }
    }

    .copyright {
        color: #8491a5;
        opacity: 1;
        margin: auto;
        height: 17px;
        font-size: 12px;
        text-align: center;
        font-weight: 400;
    }
}
</style>