<template>
        <div>
                <h1 style="text-align: center;"><strong>HƯỚNG DẪN RÚT TIỀN</strong></h1>
                <h3><strong>Hướng dẫn rút tiền</strong></h3>
                <p><span style="">1. Khi các thành viên bấm vào nút “Rút tiền”, hệ
                                thống sẽ xuất hiện cửa sổ yêu cầu xác nhận xem toàn bộ số dư đã chuyển vào tài khoản chính. Sau
                                đó hãy nhấn “Tiếp tục” để tiếp tục thực hiện.</span></p>
                <p><span style="">2. Nhập số tiền: Hãy đảm bảo rằng số tiền bạn
                                nhập vào là một số hợp lệ.</span></p>
                <p><span style="">3. Chọn một ngân hàng và số tài khoản ngân hàng
                                mà bạn muốn nhận tiền, đảm bảo tên của người nhận khớp với chủ tài khoản ngân hàng. Sau đó nhập
                                mã rút tiền.</span></p>
                <p><span style="">4. Nếu bạn có thắc mắc, vui lòng liên hệ với bộ
                                phận chăm sóc khách hàng trực tuyến 24/7, chúng tôi sẽ giúp bạn giải đáp vấn đề nhanh
                                nhất.</span></p>
                <p><span style="">5. Lưu ý: Khách hàng sẽ nhận được tiền trong
                                khoảng 5~10 phút, trong trường hợp ngân hàng bảo trì thì thời gian rút tiền sẽ tùy thuộc vào
                                tiến độ bảo trì của ngân hàng .</span></p>
                <h3><strong> Các điều cần biết khi rút tiền</strong></h3>
                <p><span style="">1. Số tiền rút tối thiểu là 200.000 VND, cấp bậc
                                thấp nhất mỗi ngày số lần rút tối đa là 10 lần. Các giao dịch rút tiền là hoàn toàn miễn phí.
                                i5bet không thu bất kỳ một khoản phí nào với các thành viên đang tham gia cá cược tại i5bet.
                                Số tiền rút tối đa và số lần rút tiền tối đa là không giới hạn. Nếu thành viên nhận khuyến mãi
                                thì cần hoàn thành tổng cược theo các chương trình khuyến mãi đó yêu cầu mới đủ điều kiện rút
                                tiền. Nếu thành viên không tham gia bất cứ chương trình khuyến mãi nào thì số tiền quý khách vừa
                                thực hiện giao dịch nạp tiền cần trải qua 1 vòng cược mới được rút tiền.</span></p>
                <p><span style="">2. i5bet có quyền được xem xét tài khoản thành
                                viên.</span></p>
                <p><span style="">3. Các điều khoản miễn trừ được quy định trong
                                các hoạt động ưu đãi khác với những điều đã nêu ở trên.</span></p>
                <p><span style="">4. Nếu quý khách có thắc mắc, vui lòng liên hệ bộ
                                phận chăm sóc khách hàng trực tuyến 24/7 để được giải đáp nhanh nhất.</span></p>
                <p><span style="">5. Đảm bảo mọi thông tin về số tài khoản ngân
                                hàng và bất kỳ thông tin nào của thành viên không được thay đổi trước lần rút tiền đầu tiên. Nếu
                                thông tin thay đổi, tất cả tiền thưởng sẽ bị khấu trừ.</span></p>
                <p><span style="">6. Trong bất kỳ trường hợp nào, i5bet đều có sở
                                hữu quyền quyết định cuối cùng.</span></p>
                <p> </p>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>