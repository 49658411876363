<template>
        <div>
                <h1 style="text-align: center;"><strong>Liên hệ chúng tôi</strong></h1>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">i5bet.com trung tâm dịch vụ hỗ trợ
                                khách hàng luôn hoạt động trong mọi khung giờ , cung cấp dịch vụ chất lượng cao 24/7.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nếu bạn có bất kỳ câu hỏi nào về việc
                                sử dụng trang web này, bạn có thể liên hệ với chuyên viên chăm sóc khách hàng để được giải đáp
                                về tất cả các vấn đề thắc mắc.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">1. Bấm vào "</span><span
                                style="color: rgb(255, 0, 0);"><strong> Dịch vụ trực tuyến </strong></span><span
                                style="color: rgb(255, 255, 255);">" liên kết, bạn có thể nhập hệ thống dịch vụ khách hàng trực
                                tuyến và liên hệ với nhân viên dịch vụ khách hàng.</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">2. Bạn cũng có thể sử dụng Email hoặc
                                điện thoại để liên lạc với nhân viên dịch vụ khách hàng</span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Email： </span><span
                                style="color: rgb(255, 0, 0);"><strong>admin@i5bet.com</strong></span></p>
                <p style="text-align: justify;"><span style="color: rgb(255, 255, 255);">Nếu có vấn đề cần liên hệ gấp bạn có
                                thể liên hệ đường dây nóng </span><span
                                style="color: rgb(255, 0, 0);"><strong>0587134113</strong></span></p>
                <p><span style="color: rgb(255, 255, 255);"> </span></p>

        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>