<template>
	<div style="position: relative;background-color:#293356;padding-bottom:100px;" class="wrap">
		<topHeader :title="$t('会员介绍')" :showRight="false" :showBack="true"></topHeader>

		<img src="@/assets/mobile/vip/banner-vi-VN.png" alt="" style="width: 100%;">
		<div class="choose flexs_r_c" style="margin: 0 auto;text-align: center;width: 100%;margin-top: 4vw;">





			<van-swipe @change="change" style="width: 70%;margin-left: 15%;" class="my-swipe" :autoplay="0"
				:show-indicators="false" lazy-render>
				<van-swipe-item v-for="item in vipBGs">
					<div style="position: relative;">
						<img :src="item.img" alt="" style="width: 70vw;">
						<div style="position: absolute;bottom: 8vw;left: 8vw;text-align: left">
							<!-- lobbyInfo.ActivityVipConf[0]['Vip'+(index1+1)] -->
							<div class="whiteWord phoneMiddle" style="color: #f3f3f3;">{{ $t("积分") }}
								>={{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[0]['Vip' + (selectIndex)]) }}
							</div>
							<div class="whiteWord phoneMiddle" style="color: #f3f3f3;margin-top: 1vw;">{{ $t("存款") }}
								>={{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[1]['Vip' + (selectIndex)]) }}
							</div>
						</div>
					</div>
				</van-swipe-item>
			</van-swipe>

		</div>
		<div class="flexs_r_c" style="width: 60%;margin: 0 auto;margin-top: 0px;position: relative;z-index: 1;">
			<div v-for="(item, index) in vipBGs" class="indicator" :class="selectIndex == index ? 'select' : 'nolmal'">
			</div>
		</div>
		<div style="color: white;vertical-align: middle;width: 90vw;margin-left: 5vw;margin-top: 6vw;" class="bigSize">{{
			$t("特权奖励") }}</div>
		<div class="flexs_sb" style="width: 90vw;margin-top:22px;margin-left: 5vw;margin-bottom: 4vw;">


			<div style="width: 43vw;height: 110px;text-align: center;background-color:rgb(53,65,109);border-radius:5px;">
				<img src="@/assets/mobile/vip/user_jiang.png" alt="" style="width: 11vw;margin-top: 10px;">
				<div class="whiteWord smallSize" style="margin-top: 3px;color:white;">{{ $t("升级奖励") }}</div>
				<div class="smallSize" style="color: rgb(218,154,54);text-align: center;margin-top: 2.5vw;">
					{{ configData.VipGet }}</div>
			</div>

			<div style="width: 43vw;height: 110px;text-align: center;background-color:rgb(53,65,109);border-radius:5px;">
				<img src="@/assets/mobile/vip/user_gift.png" alt="" style="width: 11vw;margin-top: 12px;">
				<div class="whiteWord smallSize" style="margin-top: 3px;color:white;">{{ $t("每月奖金") }}</div>
				<div class="smallSize" style="color: rgb(218,154,54);text-align: center;margin-top: 2.5vw;">
					{{ configData.WeekGet }}</div>
			</div>

		</div>

		<div v-html="lobbyInfo.ActivityVipConf.vipConf.ContentPc"
			style="margin-left: 5%;width: 90%;margin-top: 30px;color:#fff;">
		</div>
		<!-- <img :src="$img('sj_1.png')" alt="" style="margin-left: 5%;width: 90%;margin-top: 30px;"> -->
		<div style="position: relative;width: 90vw;margin: 5vw auto;">
			<div class="flexs_r_c"
				style="width: 100%;background:linear-gradient(to right,rgb(110,74,255),rgb(80,100,255));">
				<div v-for="(item, index) in 4" :class="{ borderRight: index != titleArr.length - 1 }"
					class="header middleSize boldSize">
					{{ titleArr[index] }}
				</div>

			</div>

			<div style="width: 100%;" class="flexs_r" v-if="lobbyInfo">
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" style="color: rgb(233, 216, 140);"
						:class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
						{{ 'VIP ' + (index1) }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[0]['Vip' + (index1)]) }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[1]['Vip' + (index1)]) }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[3]['Vip' + (index1)]) }}
					</div>
				</div>

			</div>


		</div>

		<div style="position: relative;width: 90vw;margin: 5vw auto;">
			<div class="flexs_r_c"
				style="width: 100%;background:linear-gradient(to right,rgb(110,74,255),rgb(80,100,255));">
				<div v-for="(item, index) in 2" :class="{ borderRight: index != titleArr.length - 1 }"
					class="header middleSize boldSize">

					<div v-if="index == 0">{{ titleArr[index] }}</div>
					<div v-else>{{ titleArr[index + 3] }}</div>
				</div>

			</div>

			<div style="width: 100%;" class="flexs_r" v-if="lobbyInfo">
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" style="color: rgb(233, 216, 140);"
						:class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
						{{ 'VIP ' + (index1) }}
					</div>
				</div>

				<!-- <div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[3]['Vip' + (index1)]) }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ $tools.formatNum(lobbyInfo.ActivityVipConf.tableConf[4]['Vip' + (index1)]) }}
					</div>
				</div> -->
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						<div v-if="index1 < 6">-</div>
						<div v-else>✓</div>
					</div>
				</div>
			</div>
		</div>


		<!-- <div style="position: relative;width: 90vw;margin: 5vw auto;">
			<div class="flexs_r_c"
				style="width: 100%;background:linear-gradient(to right,rgb(110,74,255),rgb(80,100,255));">
				<div v-for="(item, index) in 4" :class="{ borderRight: index != titleArr2.length - 1 }"
					class="header middleSize boldSize">

					<div>{{ titleArr2[index] }}</div>
				</div>

			</div>

			<div style="width: 100%;" class="flexs_r" v-if="lobbyInfo">
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" style="color: rgb(233, 216, 140);"
						:class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
						{{ 'VIP ' + (index1) }}
					</div>
				</div>

				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[7]['Vip' + (index1)] + '%' }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[8]['Vip' + (index1)] + '%' }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[6]['Vip' + (index1)] + '%' }}
					</div>
				</div>
			</div>


		</div>

		<div style="position: relative;width: 90vw;margin: 5vw auto;">
			<div class="flexs_r_c"
				style="width: 100%;background:linear-gradient(to right,rgb(110,74,255),rgb(80,100,255));">
				<div v-for="(item, index) in 4" :class="{ borderRight: index + 3 != titleArr2.length - 1 }"
					class="header middleSize boldSize" style="width: 25%;">

					<div v-if="index == 0">{{ titleArr2[index] }}</div>
					<div v-else>{{ titleArr2[index + 3] }}</div>
				</div>

			</div>

			<div style="width: 100%;" class="flexs_r" v-if="lobbyInfo">
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" style="color: rgb(233, 216, 140);"
						:class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }" v-for="(item1, index1) in 21">
						{{ 'VIP ' + (index1) }}
					</div>
				</div>

				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[5]['Vip' + (index1)] + '%' }}
					</div>
				</div>
				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[9]['Vip' + (index1)] + '%' }}
					</div>
				</div>

				<div class="flexs_colum borderRight" style="width:25%;">
					<div class="colum middleSize" :class="{ double: index1 % 2 === 0, single: index1 % 2 === 1 }"
						v-for="(item1, index1) in 21">
						{{ lobbyInfo.ActivityVipConf.tableConf[12]['Vip' + (index1)] + '%' }}
					</div>
				</div>

			</div>


		</div>  -->
		<img src="@/assets/mobile/vip/sj_2.png" alt="" style="margin-left: 5%;width: 90%;margin-top: 30px;">

	</div>
</template>

<script>
import {
	theme
} from '@/views/theme'
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";
import {
	defineComponent,
	reactive
} from 'vue'
import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import bus from "@/utils/bus.js"
import i18n from "@/language/i18n";

import {
	req,
	qs
} from "@/utils/request";
import {
	topic
} from "@/components/protocol/api";

export default {
	components: {
		topHeader
	},
	computed: {
		...mapGetters("global", ["lobbyInfo"]),
		...mapGetters("tcp", ["isLogged"]),

	},

	mounted() {
		if (this.isLogged) {
			this.getVipConfig()

		}
		var that = this;
		bus.on("onConnected", () => {
			that.getVipConfig()
		});
	},
	methods: {
		change(e) {
			this.selectIndex = e
		},
		getVipConfig() {
			var that = this

			mqant.request(topic.getVIPConfig, {}, function (data, topicName, msg) {
				that.configData = data.Data

			});
		},
	},
	data() {
		return {
			selectIndex: 0,
			configData: '',
			// titleArr: [this.$t("贵宾级别"), this.$t("有效投注"), this.$t("总充值"), this.$t("有效投注仍然存在"), this.$t("升级奖金"), this.$t(
			// 	"每月奖金"), this.$t("贵宾护理专员")],
			titleArr: [this.$t("贵宾级别"), this.$t("有效投注"), this.$t("有效投注仍然存在"), this.$t(
				"每月奖金"), this.$t("贵宾护理专员")],
			titleArr2: [this.$t("贵宾级别"), this.$t("老虎机"), this.$t("捕鱼"), this.$t("体育"), this.$t("真人视讯"), this.$t(
				"棋牌"), 'ZWIN'],
			headerType: ["BetNeed", "ChargeNeed", "KeepGradeNeed", "VipGetGold", "WeekGet"],
			vipBGs: [
				{

					img: require('@/assets/mobile/vip/VIP0.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP1.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP2.png'),

				},
				{

					img: require('@/assets/mobile/vip/VIP3.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP4.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP5.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP6.png'),

				},
				{

					img: require('@/assets/mobile/vip/VIP7.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP8.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP9.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP10.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP11.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP12.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP13.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP14.png'),


				},
				{

					img: require('@/assets/mobile/vip/VIP15.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP16.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP17.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP18.png'),



				},
				{

					img: require('@/assets/mobile/vip/VIP19.png'),



				},
				{
					img: require('@/assets/mobile/vip/VIP20.png'),



				},


			]
		};
	},
};
</script>

<style scoped>
.indicator {
	width: 2vw;
	height: 2vw;
	border-radius: 1vw;
	margin-left: 1vw;
}

.select {
	background-color: rgb(144, 162, 220);
}

.nolmal {
	background-color: rgb(97, 100, 108);
}

.header {
	color: white;
	width: 25%;
	height: 36px;
	line-height: 36px;
	text-align: center;

}

.colum {
	color: white;
	height: 30px;
	line-height: 30px;
	text-align: center;
}

.borderRight {
	border-right: .5px solid rgba(75, 85, 125, .5);
}

.double {
	background-color: rgb(52, 63, 100);


}

.single {
	background-color: rgb(43, 52, 84);

}

.wrap {
	padding-top: 50px;
}
</style>
