<template>
	<div @touchmove.prevent @mousewheel.prevent @click="handleClose()" class="wrap"
		style="width: 100%;height: 100%;position: fixed;top: 0;left: 0;z-index: 99;background-color: rgba(0, 0, 0, .4);">
		<div style="width:1089px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);" @click.stop=""
			class="flexs_r_c">
			<div style="position:relative;">
				<img :src="$img('activity/' + 'bg_db1.png')" alt="" style="width: 679px;" />
				<div style="position:absolute;left:0;top:0;width:100%;">
					<div style="padding:0 48px;margin-top:80px;text-align:center;">
						<div class="flexs_sb">
							<div style="text-align:center;">
								<div style="font-size:16px;" class="gradient-text">{{ $t('彩金池') }}</div>
								<div class="num" style="margin-top:9px;margin-left:auto;margin-right:auto;">{{
									$tools.formatNum2k(userData.awardPool) }}</div>
							</div>
							<div style="text-align:center;">
								<div style="font-size:14px;color:#ffcd7f">{{ $t('领取彩金所需投注') }}</div>
								<div
									style="width: 328px;height: 10px;background-color: #700a0e;border-radius: 10px;margin-top: 10px;overflow: hidden;border:2px solid #d48647;">
									<div style="height: 10px;" class="gradient-line" :style="progressWidth"></div>
								</div>
								<div style="font-size:14px;color:#ffd863;margin-top:8px;">
									{{ $tools.formatNum2k(userData.curBet) }}<span style="color:#fff3f3;"> / {{
										$tools.formatNum2k(userData.goalBet) }}</span></div>
							</div>
							<div style="text-align:center;">
								<div style="font-size:16px;" class="gradient-text">{{ $t('今日彩金') }}</div>
								<div class="num" style="margin-top:9px;margin-left:auto;margin-right:auto;">{{
									$tools.formatNum2k(userData.dayAward) }}</div>
							</div>
						</div>
						<div class="flexs_sb" style="padding:0 31px;margin-top:-15px;">
							<div style="text-align:center;">
								<div style="font-size:14px;color:#ffc287;">{{ $t('累计领取彩金') }}</div>
								<div class="num" style="margin-top:9px;color:white;margin-left:auto;margin-right:auto;">
									{{ $tools.formatNum2k(userData.TotalAward) }}</div>
							</div>
							<img :src="$img('activity/' + 'ding_img.gif')" alt="" style="width: 217px;" />

							<div style="position:relative;">
								<img :src="$img('activity/' + 'btn_lingqu.png')" v-if="userData.activityStatus == 1" alt=""
									style="width: 102px;" />
								<img :src="$img('activity/' + 'btn_lingqu_hui.png')" v-else alt="" style="width: 102px;" />
								<div v-if="userData.activityStatus == 1" @click="getMoney()"
									style="position:absolute;left:0;top:7px ;width:100%;text-align:center;font-size:14px;height:100%;">
									<div style="color:#ffefe5;">{{ getTime() }}</div>
									<div style="color:#ffd283;">{{ $t('领取彩金') }}</div>
								</div>
								<div v-else
									style="position:absolute;left:0;top:0px ;width:100%;text-align:center;font-size:14px;height:100%;">
									<div class="cannotClick" style="color:#ffd283; height: 100%; ">
										<span
											style="position: absolute;left: 50%;top: 45%;transform: translate(-50%,-50%);">{{
												$t('领取彩金')
											}}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="flexs_sb" style="padding:0 0px;margin-top:0px; flex-wrap: wrap;" v-if="listData">
							<div v-for="(item, index) in listData"
								style="position:relative; width: 20%;margin-bottom: 10px;" class="canClick"
								@click="selectIndex = index">
								<img :src="$img('activity/' + 'xuanz_J.png')" v-if="selectIndex == index" alt=""
									style="width: 94px;" />
								<img :src="$img('activity/' + 'wxuanz_J.png')" v-else alt="" style="width: 94px;" />

								<div style="position:absolute;left:0;top:2px;width:100%;text-align:center;color:white;font-size:16px;"
									:style="selectIndex == index ? 'top:3px' : ''">
									+<br>{{ $tools.formatNum2k(item.Charge) }}

								</div>
							</div>

						</div>
						<div style="position:relative;margin-top:10px; margin-left:auto; margin-right:auto; width: 290px;"
							@click="toRecharge()" class="canClick">
							<img :src="$img('activity/' + 'btn_cunr.png')" alt="" style="width: 290px;" />
							<div
								style="position:absolute;left:0;top:7px;font-size:18px;color:#fcf4e4;width:100%;text-align:center;">
								{{ $t('存入') }}
							</div>
						</div>
						<img :src="$img('activity/' + 'bt_title_img.png')" alt="" style="width: 220px;margin-top:10px;" />

						<div style="border:2px solid #d48647;border-radius:4px; margin-top:0px;">
							<div class="flexs_r_c" style="border-bottom:1px solid #d48647">
								<div v-for="(item, index) in headerArr" class="header"
									:style="index != 4 ? 'border-right:1px solid #d48647' : ''" :key="index"
									style="font-size:12px;">
									{{ $t(item) }}
								</div>
							</div>


							<div class="flexs_r_c" v-for="(item, index) in listData" :key="index"
								:style="index != listData.length - 1 ? 'border-bottom:1px solid #d48647' : ''">
								<div class="colum" style="border-right:1px solid #d48647;">{{
									$tools.formatNum2k(item.Charge)
								}}</div>
								<div class="colum" style="border-right:1px solid #d48647;">
									{{ $tools.formatNum2k(item.TotalGet) }}</div>
								<div class="colum" style="border-right:1px solid #d48647;">{{ item.GetCnt }}</div>
								<div class="colum" style="border-right:1px solid #d48647;">
									{{ $tools.formatNum2k(item.DayGet) }}</div>
								<div class="colum">{{ $tools.formatNum2k(item.DayBet) }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="position:relative;margin-left:10px;margin-top:28px;">
				<img @click="handleClose()" class="canClick" :src="$img('activity/' + 'btn_gb.png')" alt=""
					style="width: 42px; position: absolute;right:-42px;top:-50px;z-index: 999;">

				<img :src="$img('activity/' + 'sm_db.png')" alt="" style="width: 400px;" />
				<div
					style="position:absolute;left:30px;top:50px;width:calc(100% - 45px);overflow-y: auto; height:calc(100% - 65px); font-size:12px;line-height:19px;font-weight:700;">
					<p style="text-align: justify;"><strong>※ Nội dung khuyến mãi</strong><span
							style="color: rgb(255, 199, 119);"> :</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Thành viên khi nạp tiền có
							thể nạp thông qua bát tụ bảo với số tiền tương ứng như bảng bên dưới, số tiền này có thể được sử
							dụng cho tất cả các trò chơi tại i5bet, tiền nạp tính riêng rẻ từng đơn không tính tích
							lũy.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Sau khi nạp tiền qua bát tụ
							bảo, tiền thưởng được tặng là 100% số tiền nạp. Tiền thưởng được chia thành chu kỳ 30 - 25 - 20
							- 15 - 10 ngày để nhận tiền. Để nhận được tiền thưởng tích lũy thành viên cần hoàn thành doanh
							số cược yêu cầu theo từng mức tiền nạp và tiền thưởng.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Tổng tiền thưởng có thể được
							cộng dồn và tiền thưởng hàng ngày cũng có thể được cộng dồn đồng thời. </span></p>
					<p style="text-align: justify;"><strong>※ Ví du</strong><span
							style="color: rgb(255, 199, 119);">:</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Thành viên A, hôm nay nạp
							tiền qua bát tụ bảo có 2 đơn tiền là 3,000 điểm và 15,000 điểm, nhận được tổng điểm thưởng là
							3,000 + 15,000 = 18,000 điểm.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Trong 25 ngày đầu tiên, thành
							viên A có thể nhận được 700 điểm thưởng mỗi ngày khi hoàn thành doanh số cược hợp lệ 72,000
							điểm.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Trong 5 ngày tiếp theo, thành
							viên A có thể nhận 100 điểm thưởng mỗi ngày và chỉ cần hoàn thành 12,000 điểm doanh số cược hợp
							lệ.</span></p>
					<p style="text-align: justify;"><strong>※ Phương thức nhận thưởng</strong><span
							style="color: rgb(255, 199, 119);"> :</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">- Chọn Bát Tụ Bảo -&gt; chọn Số
							tiền muốn nạp và bấm Nạp Tiền ở sự kiện Bát Tụ Bảo -&gt; Sau khi nạp tiền thành công, hệ thống
							sẽ tự động hoàn tất tích lũy tiền thưởng ở Bát Tụ Bảo cho thành viên .</span></p>
					<p style="text-align: justify;"><strong>※ Chi tiết hoạt động</strong><span
							style="color: rgb(255, 199, 119);"> :</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">1. Tiền thưởng có thể được cộng
							dồn.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">2. Nếu doanh số cược hợp lệ
							không được hoàn thành trong ngày, tiền thắng cược trong ngày cũng sẽ bị coi là không hợp lệ và
							bị khấu trừ.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">3. Cược xổ số không được tính
							là cược hợp lệ của khuyến mãi</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">4. Tham gia sự kiện này sẽ
							không được nhận bất kỳ khuyến mãi nạp tiền khác của i5bet.</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">4. i5bet bảo lưu quyền thay
							đổi, dừng hoặc hủy bỏ chương trình khuyến mãi này bất cứ lúc nào!</span></p>
					<p style="text-align: justify;"><span style="color: rgb(251, 222, 172);">5. Tham gia nghĩa là bạn đã
							đồng ý với "Quy Tắc Và Điều Khoản Khuyến Mãi".</span></p>
				</div>
			</div>

		</div>
	</div>
	<!-- <addBank v-if="showAddBankVisible" @handleClose='showAddBankVisible = false'></addBank> -->
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { theme } from '@/views/theme'
import { bus, busName } from "@/components/core/bus";
import { http, topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";

//let addBank = require('@/views/' + theme + '/components/addBank.vue').default

export default {
	components: {
		//addBank
	},
	props: {

		data: {
			type: String,
			default: null
		}
	},
	setup() {

	},
	computed: {
		...mapGetters("tcp", ["isLogged"]),

	},
	created() {
		bus.off('HD_ReceiveSportGuess')
		bus.on('HD_ReceiveSportGuess', (val) => {
			if (val.Code == 0) {
				that.$message({
					message: that.$t('领取成功') + '!',
					type: 'success'
				});
			} else {
				that.$message({
					message: val.ErrMsg,
					type: 'error'
				});
			}
		})
		this.getConfig()
		this.getUserConfig()
	},
	methods: {
		getConfig() {
			var that = this
			mqant.request(topic.getTreasureBowlConf, {}, function (data, topicName, msg) {
				that.listData = data.Data.conf
				// console.log('sdfsf', data)

			});
		},
		getTime() {
			var hour = Math.floor(this.second / 3600)

			var minute = Math.floor((this.second - hour * 3600) / 60)
			var second = this.second - hour * 3600 - minute * 60
			hour = hour < 10 ? '0' + hour : hour
			minute = minute < 10 ? '0' + minute : minute
			second = second < 10 ? '0' + second : second

			return hour + ':' + minute + ':' + second

		},
		getUserConfig() {
			var that = this
			mqant.request(topic.getTreasureBowlUserInfo, {}, function (data, topicName, msg) {
				//that.listData = data.Data.conf
				that.userData = data.Data
				console.log('that.userData', that.userData);
				if (data.Data.goalBet != 0) {
					that.progressWidth.width = data.Data.curBet / data.Data.goalBet * 100 + '%'

				} else {
					that.progressWidth.width = "0%"
				}
				that.second = data.Data.remainTime

				if (that.timer) {
					clearInterval(this.that); // 在Vue实例销毁前，清除我们的定时器
				}
				that.timer = setInterval(() => {
					that.second--
				}, 1000)
			});
		},
		handleClose() {
			this.$emit('handleClose')
			if (this.timer) {
				clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
			}
		},
		getMoney() {
			var that = this
			mqant.request(topic.receiveTreasureBowl, {}, function (data, topicName, msg) {
				that.getUserConfig()

			});
		},
		toRecharge() {
			var that = this
			that.$router.push({
				name: 'recharge',
				params: {

					chargeNum: that.listData[that.selectIndex].Charge / 1000
				}
			})
			bus.emit('ChargeNum', that.listData[that.selectIndex].Charge / 1000);
			that.handleClose()

			// mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
			// 	if (!data.Data.myDouDouBT.length) {
			// 		that.showAddBankVisible = true
			// 		that.$message({
			// 			message: that.$t('请绑定银行卡'),
			// 			type: 'info'
			// 		});
			// 	} else {
			// 		that.$router.push({
			// 			name: 'recharge',
			// 			params: {

			// 				chargeNum: that.listData[that.selectIndex].Charge / 1000
			// 			}
			// 		})
			// 		bus.emit('ChargeNum', that.listData[that.selectIndex].Charge / 1000);
			// 		that.handleClose()

			// 	}
			// });

		}

	},

	data() {
		return {
			progressWidth: {
				width: "0%"
			},
			selectIndex: 0,
			listData: null,
			headerArr: ['存入金额', '总彩金', '彩金领取周期', '每日彩金', '每日所需投注'],
			userData: {
				TotalAward: 0,
				activityStatus: 0,
				awardPool: 0,
				curBet: 0,
				dayAward: 0,
				goalBet: 0,
				remainTime: 0

			},
			second: 0,
			time: '00:00:00',
			timer: null,
			showAddBankVisible: false,
		};
	},
};
</script>

<style scoped>
.gradient-text {
	background-image: -webkit-linear-gradient(bottom, #ffe2bd, #ffd493, #ffc15f);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	position: relative;
}

.num {
	width: 100px;
	height: 22px;
	background-color: #5e0b19;
	box-shadow: 0px 2px 0px 0px rgba(209, 79, 79, 0.51);
	border-radius: 11px;
	line-height: 22px;
	text-align: center;
	color: #ffd86e;
	font-size: 14px;
}

.gradient-line {
	background: linear-gradient(to right, #e95800, #ffc658);
}

.colum {
	width: 20%;
	text-align: center;
	height: 30px;
	line-height: 30px;
	color: #ac3c28;
	background-color: #f3d8a3;
	font-size: 12px;
	font-weight: 700;
}

.header {
	width: 20%;
	text-align: center;
	height: 25px;
	line-height: 25px;
	font-size: 14px;
	color: #f9ce91;
	background-color: #b01e26;
	font-weight: 700;
}

strong {
	color: #ffc778;
}
</style>