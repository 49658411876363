<template>
        <div>
                <h1 style="text-align: center;"><strong>MIỄN TRÁCH NHIỆM</strong></h1>
                <p><span style="">· i5bet không chịu trách nhiệm về tính chính xác
                                hoặc tính kịp thời về nội dung các trang web được kết nối với một trang web bên ngoài.</span>
                </p>
                <p><span style="">· Sử dụng mạng lưới này để cá cược có thể dẫn đến
                                trách nhiệm pháp lý đối với cư dân hoặc cá nhân ở một số quốc gia. i5bet không có ý định áp
                                dụng mạng lưới này cho những cá nhận ở một quốc gia mà hoạt động cá cược là bất hợp pháp.</span>
                </p>
                <p><span style="">· Thành viên phải đồng ý rằng tất cả các tuyên bố
                                miễn trừ trách nhiệm được thiết lập công bằng. Thành viên chịu hết mọi rủi ro và lợi nhuận, được
                                thể hiện từ mối quan tâm của Thành viên cũng như sự hiểu biết và hỗ trợ của Thành viên đối với
                                i5bet. Thành viên cần xác nhận rằng điều khoản này là hợp pháp và khả thi.</span></p>
                <p><span style="">· Trò chơi trực tuyến và không trực tuyến là hợp
                                pháp dựa theo một số quy tắc pháp luật. Ở một số quốc gia, đặt cược được xem là hành vi bất hợp
                                pháp.i5bet và các đối tác, nhân viên và đại lý liên quan không chịu trách nhiệm cho bất kì vi
                                phạm pháp luật địa phương của quý khách hàng.</span></p>
                <p><span style="">· Khách hàng phải đáp ứng được độ tuổi pháp lý (
                                giới hạn độ tuổi hợp pháp tại khu vực cư trú của quý khách) Nếu chúng tôi phát hiện khách hàng
                                nào dưới 18 tuổi, i5bet có quyền đóng băng hoặc đóng tài khoản trò chơi có liên quan và không
                                hoàn lại tiền.</span></p>
                <p><span style="">· Thành viên nên thiết lập tài khoản máy tính để
                                tránh trẻ em tiếp cận với trò chơi. Chúng tôi rất tiếc khi phải nói rằng i5bet và các đối tác,
                                nhân viên, đại lý không chịu trách nhiệm pháp lý đối với bất cứ hành vi vi phạm nào phát sinh từ
                                khách hàng.</span></p>
                <p><span style="">· Thành viên khi tham gia vào các dịch vụ của
                                i5bet, nếu có gặp sự cố về bất cứ trò chơi, phần mềm, thông tin, hoặc sự cố kĩ thuật, các vấn
                                đề về mạng…(vệ tinh kém, mạng yếu, gián đoạn…) do sơ suất của con người gây ra. i5bet và các
                                đối tác, nhân viên, đại lý liên quan không chịu trách nhiệm.</span></p>
                <p><span style="">· i5bet và các đối tác, nhân viên, đại lý không
                                chịu trách nhiệm cho sự chậm trễ hoặc không phân phối kịp thời các dịch vụ và thông tin do bên
                                thứ ba cung cấp.</span></p>
                <p><span style="">· i5bet luôn cố gắng hết sức để cung cấp cho
                                Thành viêncác dịch vụ an toàn nhất, chất lượng cao nhất và đáng tin cậy nhưng không đảm bảo cung
                                cấp kịp thời và chính xác, cũng như sự ổn định của dịch vụ khi có sự tấn công của virus hoặc sâu
                                máy tính.</span></p>
                <p><span style="">· i5bet có quyền đình chỉ, chấm dứt, sửa đổi,
                                xóa hoặc thêm dịch vụ ngay lập tức hoặc vĩnh viễn mà không cần thông báo cho khách hàng. i5bet
                                và các đối tác, nhân viên và đại lý không chịu trách nhiệm.</span></p>
                <p><span style="">· Thành viên nên duy trì trò chơi có kết nối ổn
                                định với i5bet. i5bet và các đối tác, nhân viên, đại lý không chịu trách nhiệm cho các trò
                                chơi, thông tin phản hồi, cũng như các trò chơi miễn phí bị ngắt kết nối bảo vệ do mạng lưới của
                                khách hàng bất ổn chỉ vì muốn trả lại tiền cược ban đầu.</span></p>
                <p><span style="">· Nếu Thành viên cố ý hoặc không cố ý hay tiết lộ
                                thông tin tài khoản để bên thứ ba đánh cắp tài khoản trò chơi của Thành viên dưới bất cứ hình
                                thức nào, tất cả các thiệt hại khách hàng phải tự chịu trách nhiệm. Chúng tôi rất tiếc phải nói
                                rằng i5bet và các đối tác, nhân viên và đại lý không chịu trách nhiệm.</span></p>
                <p><span style="">· i5bet luôn được quyền quyết định cuối cùng
                                trong bất cứ trường hợp nào.</span></p>
                <p style="text-align: left;"> </p>
                <p> </p>
        </div>
</template>

<script setup>

</script>
<style lang="scss" scoped>
h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
}

p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
}
</style>