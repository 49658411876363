<template>
    <div class="help">
        <div class="container">
            <el-menu class="ELmenu" active-text-color="#ffd04b" background-color="#545c64"
                :default-active="selecteIndex.toString()" :default-openeds="['1']" text-color="#fff">
                <el-menu-item v-for="( item, index) in leftList " :index="index.toString()" @click="selecteIndex = index">
                    <span>{{ item.name }}</span>
                </el-menu-item>
                <!-- <el-sub-menu index="1">
                    <template #title>
                        <span>Hướng dẫn người mới</span>
                    </template>
                    <el-menu-item index="11" @click="selecteIndex = 0">Đăng ký</el-menu-item>
                    <el-menu-item index="12" @click="selecteIndex = 1">Nạp tiền</el-menu-item>
                    <el-menu-item index="13" @click="selecteIndex = 2">Rút tiền</el-menu-item>
                    <el-menu-item index="14" @click="selecteIndex = 3">Điều khoản</el-menu-item>
                </el-sub-menu> -->
            </el-menu>
            <div class="right">
                <component style="font-size:18px;" :is="leftList[selecteIndex].component"></component>
            </div>
        </div>
    </div>
</template>

<script setup>
import TextAboutUs from "@/views/pc/components/help/TextAboutUs.vue"
import TextAgent from "@/views/pc/components/help/TextAgent.vue"
import TextCondition from "@/views/pc/components/help/TextCondition.vue"
import TextContactUs from "@/views/pc/components/help/TextContactUs.vue"
import TextDrawMoney from "@/views/pc/components/help/TextDrawMoney.vue"
import TextLiability from "@/views/pc/components/help/TextLiability.vue"
import TextPrivacy from "@/views/pc/components/help/TextPrivacy.vue"
import TextRecharge from "@/views/pc/components/help/TextRecharge.vue"
import TextStatement from "@/views/pc/components/help/TextStatement.vue"
import TextTreaty from "@/views/pc/components/help/TextTreaty.vue"


import { ref, reactive, watch } from "vue"
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
let selecteIndex = ref(0);
const curRoute = useRoute();
let leftList = reactive([
    { name: 'i5bet', component: TextAboutUs },
    { name: 'ĐIỀU KHOẢN VÀ ĐIỀU KIỆN', component: TextTreaty },
    { name: 'ĐIỀU KIỆN KHUYẾN MÃI', component: TextCondition },
    { name: 'HỢP TÁC ĐẠI LÝ', component: TextAgent },
    { name: 'HƯỚNG DẪN NẠP TIỀN', component: TextRecharge },
    { name: 'HƯỚNG DẪN RÚT TIỀN', component: TextDrawMoney },
    { name: 'MIỄN TRÁCH NHIỆM', component: TextStatement },
    { name: 'QUYỀN RIÊNG TƯ', component: TextPrivacy },
    { name: 'TÍNH TRÁCH NHIỆM', component: TextLiability },
    { name: 'LIÊN HỆ', component: TextContactUs },
])
watch(
    () => curRoute.query.index, () => {
        if (curRoute.query.index == null
            || curRoute.query.index == undefined) {
            return;
        }
        selecteIndex.value = curRoute.query.index;
    }, { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.help {
    position: relative;
    margin: 0 auto;
    width: 1400px;
    padding-top: 24px;

    .container {
        display: flex;
        border-radius: 8px;
        overflow: hidden;

        .ELmenu {
            min-width: 280px;
            border-right: 0;
            margin-right: 14px;
            border-radius: 8px;
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            list-style: none;
        }

        .el-menu {
            border-right: 0;
            background: #292e3b;
        }

        .right {
            background: #292e3b;
            color: #f9f9f9;
            padding: 20px 20px 44px;
            width: 1156px;
            border-radius: 8px;
            overflow: hidden;
        }
    }
}
</style>